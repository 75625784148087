import { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MenuShortcut from './MenuShortcut';

import useI18n from 'i18n';
import { useBridgeApi, useDispatch, useSelector } from 'Hooks';
import CategoryItem from './CategoryItem';
import Row from './Row';
import { checkOutOfStock } from '../../../utils/inventory';
import {
  getAllCategories,
  getAllCategoriesVariables,
  getAllCategories_allCategories_items as Item,
} from './__queries__/getAllCategories';
import { useShoppingCart, useShoppingCartApi } from 'Components/ShoppingCartUniverse';
import PlaceholderItem from './PlaceholderItem';
import useEntryFinder from 'Hooks/useEntryFinder';
import { useStorage } from 'Components/Storage';
import { useConfig } from 'Components/ConfigProvider';
import { setLoginModal } from '../../../actions';

const Padding = styled.div`
  height: 40px;
  background: #f8f8fb;
`;

export const CATEGORIES_QUERY = gql`
  query getAllCategories($companyId: ID!, $maxCategories: Int!, $maxItems: Int!) {
    allCategories(
      orderBy: order_DESC
      first: $maxCategories
      filter: { company: { id: $companyId }, deleted: false }
    ) {
      id
      order
      name
      nameLang {
        id
        se
        en
        es
      }
      items(orderBy: order_DESC, first: $maxItems, filter: { deleted: false }) {
        id
        order
        disableMembershipDiscount
        title
        titleLang {
          id
          se
          en
          es
        }
        descriptionLang {
          id
          se
          en
          es
        }
        defaultPrice
        image {
          id
          file {
            id
            url
          }
        }
      }
    }
  }
`;

/**
 * Home page categories component
 */
const Categories = () => {
  const config = useConfig();
  const dispatch = useDispatch();
  const maxCategories = config.MAX_CATEGORIES ?? 3;
  const minRequestCategories = 7;
  const maxItems = 5;
  const params = useParams<{ venue_id?: string }>();
  const venueId = params?.venue_id || '';
  const { i18n } = useI18n();
  const { push } = useHistory();
  const api = useBridgeApi();
  const venue = useSelector((state) => state.venue);
  const qrOrder = useSelector((state) => state.qrOrder);
  const shoppingCartApi = useShoppingCartApi();
  const shoppingCart = useShoppingCart();
  const findEntryFromItem = useEntryFinder();
  const inventory = useSelector((state) => state.inventory ?? []);
  const [storageData] = useStorage();
  const authToken = storageData.authToken;
  const isLoggedIn = !!authToken;
  const categoriesLoadAttempt = useRef<number>(0);
  const [categoriesLoading, setCategoriesLoading] = useState(true);

  const [getCategories, { data, loading }] = useLazyQuery<
    getAllCategories,
    getAllCategoriesVariables
  >(CATEGORIES_QUERY);

  useEffect(() => {
    // Get first categories
    fetchCategories(minRequestCategories);
  }, []);

  // Refetch categories with updated quantity
  const fetchCategories = (quantity: number) => {
    categoriesLoadAttempt.current = categoriesLoadAttempt.current + 1;
    getCategories({
      variables: {
        companyId: config.COMPANY_ID,
        maxCategories: quantity,
        maxItems,
      },
    });
  };

  // Categories that have items in the menu
  const firstCategories = useMemo(() => {
    return (data?.allCategories || [])
      .map((category) => {
        return {
          id: category.id,
          name: category.name,
          nameLang: category.nameLang,
          items: category.items.filter((item) => findEntryFromItem(item.id)).slice(0, maxItems),
        };
      })
      .filter(({ items }) => items.length > 0)
      .slice(0, maxCategories);
  }, [data, venue, findEntryFromItem]);

  // Get more categories that have items in the menu until we have enough categories
  useEffect(() => {
    if (loading || !data) {
      return;
    }

    if (firstCategories.length < 1) {
      // The difference between the number of categories (have items) that we already have and that we need to get
      const count = maxCategories - firstCategories.length;
      const quantity = data.allCategories.length + count;
      if (categoriesLoadAttempt.current < 3) {
        fetchCategories(quantity);
      } else {
        setCategoriesLoading(false);
      }
    } else {
      setCategoriesLoading(false);
    }
  }, [data, loading, firstCategories]);

  const handleClick = (item: Item, categoryId: string) => {
    const entry = findEntryFromItem(item.id);

    // Joar: Don't think this would ever happen since we filter by entry above
    if (!entry) {
      return;
    }

    // If we are not adding to cart right away, redirect to item page
    if (!entry.instant) {
      push(`/order/${venueId}/item/${item.id}`);
      return;
      // Allow "Anonymous Purchase" check.
    } else if (!config.ANONYMOUS_PURCHASE && !isLoggedIn && (!qrOrder || !storageData.qrOrder)) {
      // If restaurant doesn't allow anonymous purchases & the user is not logged in, show login modal (Unless it's table ordering).
      dispatch(setLoginModal(true));
      return;
    }

    toast(i18n('Toast.Add', i18n(item.titleLang)), {
      position: 'bottom-center',
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
    api.vibrate('impactLight');
    if (entry && item) {
      shoppingCartApi.addItem(
        i18n(item.titleLang),
        entry.item.id,
        entry.id,
        [],
        [],
        entry.price,
        item.disableMembershipDiscount,
        categoryId,
      );
    }
  };

  const renderThumbnails = useMemo(() => {
    return firstCategories.some(({ items }) => items.some((item) => item.image));
  }, [firstCategories]);

  if (!firstCategories.length || categoriesLoading) {
    return (
      <>
        <Row title="Laddar...">
          <PlaceholderItem />
          <PlaceholderItem />
          <PlaceholderItem />
          <PlaceholderItem />
        </Row>
        <Row title="Laddar...">
          <PlaceholderItem />
          <PlaceholderItem />
          <PlaceholderItem />
          <PlaceholderItem />
        </Row>
        <Row title="Laddar...">
          <PlaceholderItem />
          <PlaceholderItem />
          <PlaceholderItem />
          <PlaceholderItem />
        </Row>
      </>
    );
  }

  const toMenu = (categoryId?: string) => {
    return categoryId
      ? push(`/order/${venueId}/menu/${categoryId}`)
      : push(`/order/${venueId}/menu`);
  };

  return (
    <>
      {firstCategories.map(({ id, nameLang, items }) => {
        // When checking stock for a category we disregard the count
        const categoryOutOfStock = checkOutOfStock(id, inventory, true);

        return (
          <Row
            key={id}
            title={i18n(nameLang)}
            categoryId={id}
            toMenu={toMenu}
            outOfStock={categoryOutOfStock}>
            {items.map((item) => {
              // This will never return null
              const entry = findEntryFromItem(item.id)!;

              return (
                <CategoryItem
                  key={item.id}
                  title={item.titleLang}
                  price={entry.price}
                  image={item?.image?.file?.url}
                  onClick={() => handleClick(item, id)}
                  outOfStock={{
                    item: checkOutOfStock(item.id, inventory, false, shoppingCart.items),
                    category: categoryOutOfStock,
                  }}
                  renderThumbnails={renderThumbnails}
                />
              );
            })}
          </Row>
        );
      })}
      <MenuShortcut toMenu={toMenu} />
      <Padding />
    </>
  );
};

export default Categories;
