import { useSelector } from 'Hooks';
import { useCallback, useMemo } from 'react';
import { getVenueMenus_EAT_HERE_results_menu_menuEntries } from 'Components/MenuManager/__queries__';

const useEntryFinder = () => {
  const menu = useSelector((state) => state.menu);

  // Invert the menu for very fast lookup later
  const invertedMenu = useMemo(() => {
    if (!menu) {
      return {};
    }

    return menu?.menuEntries.reduce((obj, entry) => {
      obj[entry.item.id] = entry;

      return obj;
    }, {} as Record<string, getVenueMenus_EAT_HERE_results_menu_menuEntries>);
  }, [menu]);

  /**
   * Searches the currently active menu for an entry of the item you pass in
   *
   * Primarily used for finding the price of an item
   *
   * @param id The Item ID that you wish to find the price for
   * @returns A MenuEntry or null
   */
  const findEntryFromItem: (
    id: string,
  ) => getVenueMenus_EAT_HERE_results_menu_menuEntries | null = useCallback(
    (id: string) => {
      return invertedMenu[id] || null;
    },
    [menu],
  );

  return findEntryFromItem;
};

export default useEntryFinder;
