const translations: {
  [key: string]: {
    en: string | any;
    se: string | any;
    es: string | any;
    no: string | any;
    tr: string | any;
    dk: string | any;
  };
} = {
  'Landingpage.OrderFood': {
    en: 'Order food',
    se: 'Beställ mat',
    es: 'Ver menu y pedir',
    no: 'Bestill mat',
    tr: 'Sipariş ver',
    dk: 'Bestil mad',
  },
  'Landingpage.Membership': {
    en: 'Membership',
    se: 'Medlemskap',
    es: 'Membresía',
    no: 'Medlemskap',
    tr: 'Üyelik',
    dk: 'Medlemsskab',
  },
  'Landingpage.MemberCard.Title': {
    en: (company: string) => `${company} membership card`,
    se: (company: string) => `${company} medlemskort`,
    es: (company: string) => `${company} tarjeta de membresía`,
    no: (company: string) => `${company} medlemskort`,
    tr: (company: string) => `${company} üyelik kartı`,
    dk: (company: string) => `${company} medlemsskabskort`,
  },
  'Landingpage.MemberCard.Text': {
    en: 'Receive points and get offers',
    se: 'Samla poäng och få erbjudanden',
    es: 'Recibe puntos y obtén ofertas',
    no: 'Opptjen poeng og få tilbud',
    tr: 'Puan ve teklif alın',
    dk: 'Optjen point og få tilbud',
  },
  'Landingpage.MemberCard.Hello': {
    en: (name: string) => `Hello, ${name}`,
    se: (name: string) => `Hej, ${name}`,
    es: (name: string) => `Hola, ${name}`,
    no: (name: string) => `Hei, ${name}`,
    tr: (name: string) => `Merhaba, ${name}`,
    dk: (name: string) => `Hej, ${name}`,
  },
  'Landingpage.Membership.Level': {
    en: 'Your membership level',
    se: 'Din medlemsnivå',
    es: 'Tu nivel de membresía',
    no: 'Ditt medlemsnivå',
    tr: 'Üyelik seviyeniz',
    dk: 'Dit medlemsskabsniveau',
  },
  'Landingpage.Membership.Text': {
    en: 'Now you can order in our app and receive points.',
    se: 'Nu kan du handla i vår app och samla poäng.',
    es: 'Ahora puedes ordenar en nuestra app y recibir puntos.',
    no: 'Nå kan du handle i appen vår og samle poeng.',
    tr: 'Artık uygulamamızdan sipariş verebilir ve puan kazanabilirsiniz.',
    dk: 'Nu kan du bestille i vores app og optjene point.',
  },
  'Landingpage.Readmore': {
    en: (topic: string) => `Read more about ${topic}`,
    se: (topic: string) => `Läs mer om ${topic}`,
    es: (topic: string) => `Leer más sobre ${topic}`,
    no: (topic: string) => `Les mer om ${topic}`,
    tr: (topic: string) => `${topic} hakkında daha fazlasını okuyun`,
    dk: (topic: string) => `Læs mere om ${topic}`,
  },
  'Landingpage.News': {
    en: 'News',
    se: 'Nyheter',
    es: 'Noticia',
    no: 'Nyheter',
    tr: 'Yenilikler',
    dk: 'NYHED',
  },
  'Landingpage.FindOnInstagram': {
    en: 'We are on Instagram, follow us',
    se: 'Vi finns på instagram, följ oss',
    es: 'Estamos en Instagram, síguenos',
    no: 'Følg oss på Instagram',
    tr: "Instagram'dayız, bizi takip edin",
    dk: 'Følg os på instagram',
  },
  'TableLandingPage.Language': {
    en: 'English',
    se: 'Svenska',
    es: 'Español',
    no: 'Norsk',
    tr: 'İngilizce',
    dk: 'Engelsk',
  },
  'TableLandingPage.TableNumber': {
    en: 'Table no.',
    se: 'Bordsnummer',
    es: 'N° de mesa',
    no: 'Bordnummer',
    tr: 'Masa numarası',
    dk: 'Bord nr.',
  },
  'TableLandingPage.LoggedIn.Message': {
    en: 'Welcome, ',
    se: 'Välkommen,',
    es: 'Bienvenido, ',
    no: 'Velkommen',
    tr: 'Hoş geldiniz,',
    dk: 'Velkommen',
  },
  'TableLandingPage.Member': {
    en: 'Already a regular?',
    se: 'Redan en Stammis?',
    es: '¿Ya eres cliente?',
    no: 'Allerede registrert?',
    tr: 'Zaten düzenli müşteri misiniz?',
    dk: 'Allerede stamgæst?',
  },
  'TableLandingPage.Login': {
    en: 'Log in here!',
    se: 'Logga in här!',
    es: 'Inicia tu sesión',
    no: 'Logg inn',
    tr: 'Buradan giriş yapın!',
    dk: 'Log ind her!',
  },
  'TableLandingPage.Welcome': {
    en: 'Welcome!',
    se: 'Välkommen!',
    es: 'Bienvenido',
    no: 'Velkommen!',
    tr: 'Hoş geldiniz!',
    dk: 'Velkomst!',
  },
  'TableLandingPage.Begin': {
    en: 'Start ordering',
    se: 'Starta beställning',
    es: 'Comenzar pedido',
    no: 'Start bestilling',
    tr: 'Sipariş vermeye başlayın',
    dk: 'Begynd bestilling',
  },
  'TableLandingPage.SelectYourLanguage': {
    en: 'Choose language',
    se: 'Välj språk',
    es: 'Elige tu idioma',
    no: 'Velg språk',
    tr: 'Dil seçin',
    dk: 'Vælg sprog',
  },
  'TableLandingPage.SelectYourLanguageButton': {
    en: 'Choose',
    se: 'Välj',
    es: 'Seleccione',
    no: 'Velg',
    tr: 'Seç',
    dk: 'Vælg',
  },
  'Startpage.Offers.Title': {
    en: 'Offers',
    se: 'Erbjudanden',
    es: 'Ofertas',
    no: 'Tilbud',
    tr: 'Teklifler',
    dk: 'Tilbud',
  },
  'Startpage.Offers.LoggedIn.Title': {
    en: 'Active Offers',
    se: 'Aktiva Erbjudanden',
    es: 'Ofertas Activos',
    no: 'Aktive tilbud',
    tr: 'Aktif Teklifler',
    dk: 'Aktivér tilbud',
  },
  'Startpage.Offers.PopUpUndo': {
    en: 'Undo',
    se: 'Ångra',
    es: 'Deshacer',
    no: 'Angre',
    tr: 'Geri al',
    dk: 'Fortryd',
  },
  'Startpage.LatestOrder.Title': {
    en: 'Your latest orders',
    se: 'Dina senaste beställningar',
    es: 'Tus últimos pedidos',
    no: 'Dine siste bestillinger',
    tr: 'En son siparişleriniz',
    dk: 'Dine seneste bestillinger',
  },
  'Startpage.Popular.Title': {
    en: 'Popular',
    se: 'Populära',
    es: 'Popular',
    no: 'Populære',
    tr: 'Popüler',
    dk: 'Populær',
  },
  'Startpage.Button.Start': {
    en: 'Start',
    se: 'Start',
    es: 'Empezar',
    no: 'Start',
    tr: 'Başla',
    dk: 'Starte',
  },
  'Startpage.Button.Menu': {
    en: 'Menu',
    se: 'Meny',
    es: 'Menú',
    no: 'Meny',
    tr: 'Menü',
    dk: 'Menu',
  },
  'Startpage.Button.Cart': {
    en: 'Cart',
    se: 'Varukorg',
    es: 'Tu Pedido',
    no: 'Handlekurv',
    tr: 'Sepet',
    dk: 'Kurv',
  },
  'Startpage.Offer.Modal.Button': {
    en: 'Continue',
    se: 'Gå vidare',
    es: 'Continuar',
    no: 'Fortsett',
    tr: 'Devam et',
    dk: 'Fortsæt',
  },
  'Startpage.ServiceType.Modal.Title': {
    en: 'Select Service Type',
    se: 'Välj Servicetyp',
    es: 'Seleccione el tipo de servicio',
    no: 'Velg bestillingstype',
    tr: 'Hizmet Türünü Seçin',
    dk: 'Vælg Service Type',
  },
  'Startpage.ServiceType.Modal.Text': {
    en: 'Do you want to eat here or take away?',
    se: 'Vill du äta här eller ta med?',
    es: '¿Quieres comer aquí o para llevar?',
    no: 'Vil du spise her eller ta med?',
    tr: 'Burada mı yemek istersiniz, yoksa paket mi?',
    dk: 'Vil du spise her eller take away?',
  },
  'StampCard.Details.Description': {
    en: 'The discount is automatically deducted at checkout when you have filled in all stamps.',
    se: 'Rabatten dras av automatiskt i kassan när du har fyllt i alla stämplar.',
    es:
      'El descuento se deduce automáticamente al finalizar la compra cuando haya completado todos los sellos.',
    no: 'Rabatten legges til automatisk ved betaling når du har fylt inn alle stempler.',
    tr: 'İndirim, tüm damgaları doldurduğunuzda ödeme sırasında otomatik olarak düşer.',
    dk: 'Rabatten trækkes automatisk ved kassen, når du har udfyldt alle frimærker.',
  },
  'StampCard.Finish': {
    en: 'Congratulations! You’ve unlocked a new offer!',
    se: 'Grattis! Du har låst upp ett nytt erbjudande!',
    es: '¡Felicidades! ¡Has desbloqueado una nueva oferta!',
    no: 'Gratulerer! Du har låst opp et nytt tilbud!',
    tr: 'Tebrikler! Yeni bir teklifin kilidini açtınız!',
    dk: 'Tillykke! Du har låst op for et nyt tilbud!',
  },
  'Push.Title': {
    en: 'Do not miss out!',
    se: 'Missa inget!',
    es: '¡No te lo pierdas!',
    no: 'Gå ikke glipp av nye tilbud',
    tr: 'Kaçırmayın!',
    dk: 'Gå ikke glip af det!',
  },
  'Push.Text': {
    en: 'Enable push notifications so you never miss an offer or news.',
    se: 'Aktivera pushnotiser så att du aldrig går mist om ett erbjudande eller en nyhet.',
    es: 'Habilite las notificaciones push para que nunca te pierdas una oferta o noticia.',
    no: 'Aktiver pushvarslinger, så går du aldri glipp av aktuelle tilbud og nyheter.',
    tr: 'Hiçbir teklifi veya haberi kaçırmamak için anında bildirimleri etkinleştirin.',
    dk: 'Aktiver push-meddelelser, så du aldrig går glip af et tilbud eller en nyhed.',
  },
  'Push.Button': {
    en: 'Ok',
    se: 'Ok',
    es: 'Bien',
    no: 'Ok',
    tr: 'Tamam',
    dk: 'Okay',
  },
  'Navigation.Welome': {
    en: 'Welcome.',
    se: 'Välkommen.',
    es: 'Bienvenido.',
    no: 'Velkommen',
    tr: 'Hoş geldin.',
    dk: 'Velkomst.',
  },
  'Navigation.InfoText': {
    en: (company: string) => `Sign in or create an account for ${company}.`,
    se: (company: string) => `Logga in eller skapa ett ${company} konto.`,
    es: (company: string) => `Iniciar sesión o crear una cuenta para ${company}.`,
    no: (company: string) => `Logg inn eller opprett en konto for ${company}.`,
    tr: (company: string) => `${company} için oturum açın veya bir hesap oluşturun.`,
    dk: (company: string) => `Log ind, eller opret en konto for ${company}.`,
  },
  'Navigation.MembershipLevel': {
    en: 'Membership level',
    se: 'Medlemsnivå',
    es: 'Nivel de membresía',
    no: 'Medlemsnivå',
    tr: 'Üyelik düzeyi',
    dk: 'Medlemsniveau',
  },
  'Navigation.CreateAccount': {
    en: 'Create account',
    se: 'Skapa konto',
    es: 'Crear cuenta',
    no: 'Opprett konto',
    tr: 'Hesap oluştur',
    dk: 'Opret konto',
  },
  'Navigation.Signin': {
    en: 'Sign in',
    se: 'Logga in',
    es: 'Inicia sesión',
    no: 'Logg inn',
    tr: 'Kayıt ol',
    dk: 'Log ind',
  },
  'Navigation.Home': {
    en: 'Home',
    se: 'Hem',
    es: 'Home',
    no: 'Hjem',
    tr: 'Ana sayfa',
    dk: 'Hjem',
  },
  'Navigation.OrderFood': {
    en: 'Order food',
    se: 'Beställ mat',
    es: 'Pedir comida',
    no: 'Bestill mat',
    tr: 'Yemek sipariş et',
    dk: 'Bestil mad',
  },
  'Navigation.OrderHistory': {
    en: 'Order history',
    se: 'Orderhistorik',
    es: 'Historial de pedidos',
    no: 'Ordrehistorikk',
    tr: 'Sipariş geçmişi',
    dk: 'Ordrehistorik',
  },
  'Navigation.InvoiceCustomer': {
    en: 'Sign up for invoicing',
    se: 'Bli företagskund',
    es: 'Registrarse para facturación',
    no: 'Bli bedriftskunde',
    tr: 'Faturalandırma için kaydolun',
    dk: 'Tilmeld dig fakturering',
  },
  'Navigation.PaymentMethods': {
    en: 'Payment methods',
    se: 'Betalmedel',
    es: 'Forma de pago',
    no: 'Betalingsmåte',
    tr: 'Ödeme yöntemleri',
    dk: 'Betalingsmetoder',
  },
  'Navigation.MembershipCard': {
    en: 'Membership',
    se: 'Medlemskort',
    es: 'Membresía',
    no: 'Medlemskort',
    tr: 'Üyelik',
    dk: 'Medlemskab',
  },
  'Navigation.News': {
    en: 'News',
    se: 'Nyheter',
    es: 'Noticias',
    no: 'Nyheter',
    tr: 'Yenilikler',
    dk: 'Nyheder',
  },
  'Navigation.ActivateCode': {
    en: 'Activate code',
    se: 'Aktivera kod',
    es: 'Activar código',
    no: 'Aktiver kode',
    tr: 'Kodu etkinleştir',
    dk: 'Aktiver kode',
  },
  'Navigation.Support': {
    en: 'Support',
    se: 'Support',
    es: 'Soporte',
    no: 'Support',
    tr: 'Destek',
    dk: 'Vejledning',
  },
  'Navigation.Signout': {
    en: 'Sign out',
    se: 'Logga ut',
    es: 'Cerrar sesión',
    no: 'Logg ut',
    tr: 'Oturumu kapat',
    dk: 'Log ud',
  },
  'Navigation.Version': {
    en: 'Version',
    se: 'Version',
    es: 'Versión',
    no: 'Versjon',
    tr: 'Sürüm',
    dk: 'Version',
  },
  'Session.Error': {
    en: 'Something went wrong, please try again.',
    se: 'Något har gått fel, vänligen försök igen.',
    es: 'Algo salió mal, por favor inténtalo de nuevo.',
    no: 'En feil har oppstått, vennligst prøv igjen.',
    tr: 'Bir şeyler ters gitti, lütfen tekrar deneyin.',
    dk: 'Noget gik galt. Prøv venligst igen.',
  },
  'Signup.Title': {
    en: 'Create account',
    se: 'Skapa konto',
    es: 'Crear cuenta',
    no: 'Opprett konto',
    tr: 'Hesap oluştur',
    dk: 'Opret konto',
  },
  'Signup.Step': {
    en: '/',
    se: 'av',
    es: 'de',
    no: 'av',
    tr: '/',
    dk: '/',
  },
  'Signup.Name': {
    en: 'Name',
    se: 'Namn',
    es: 'Nombre',
    no: 'Navn',
    tr: 'İsim',
    dk: 'Navn',
  },
  'Signup.Name.Placeholder': {
    en: 'Enter your name',
    se: 'Fyll i ditt namn här',
    es: 'Introduce tu nombre',
    no: 'Fyll inn ditt navn her',
    tr: 'Adınızı girin',
    dk: 'Indtast dit navn',
  },
  'Signup.Phone': {
    en: 'Phone number',
    se: 'Mobilnummer',
    es: 'Número de móvil',
    no: 'Mobilnummer',
    tr: 'Telefon numarası',
    dk: 'Telefonnummer',
  },
  'Signup.Phone.Placeholder': {
    en: 'Validation of your account',
    se: 'Validering av ditt konto',
    es: 'Validación de su cuenta',
    no: 'Validering av brukerkonto',
    tr: 'Hesabınızın doğrulanması',
    dk: 'Bekræftelse af din konto',
  },
  'Signup.Email': {
    en: 'Email',
    se: 'Mailadress',
    es: 'Correo electrónico',
    no: 'E-postadresse',
    tr: 'E-posta',
    dk: 'E-mail',
  },
  'Signup.Email.Placeholder': {
    en: 'Name@email.com',
    se: 'Namn@email.se',
    es: 'Nombre@email.es',
    no: 'Navn@epost.no',
    tr: 'isim@e-posta.com',
    dk: 'Navn@e-mail.com',
  },
  'Button.Next': {
    en: 'Next',
    se: 'Nästa',
    es: 'Próximo',
    no: 'Neste',
    tr: 'Sonraki',
    dk: 'Næste',
  },
  'Button.SubmitButton': {
    en: 'Complete',
    se: 'Slutför',
    es: 'Terminar',
    no: 'Fullfør',
    tr: 'Tamamla',
    dk: 'Fuldført',
  },
  'Signup.Discount': {
    en: 'Discount code',
    se: 'Rabattkod',
    es: 'Código de descuento',
    no: 'Rabattkode',
    tr: 'İndirim kodu',
    dk: 'Rabatkode',
  },
  'Signup.Discount.Placeholder': {
    en: 'Enter discount code',
    se: 'Ange eventuell rabattkod',
    es: 'Introduzca el código de descuento',
    no: 'Oppgi eventuell rabattkode',
    tr: 'İndirim kodunu girin',
    dk: 'Indtast rabatkode',
  },
  'Signup.Discount.Infotext': {
    en: 'Here you can enter a discount code for exclusive memberships. Not required.',
    se: 'Här kan du ange rabattkod för exklusiva medlemskap. Ej obligatoriskt.',
    es: 'Aquí puede ingresar un código de descuento para membresías exclusivas. No es necesario.',
    no: 'Her kan du angi en eventuell rabattkode, dersom du har mottatt det.',
    tr: 'Özel üyelikler için indirim kodunu buraya girebilirsiniz. Zorunlu değil.',
    dk: 'Her kan du indtaste en rabatkode til eksklusive medlemskaber. Ikke påkrævet.',
  },
  'SignUp.Error.RegistrationFailed': {
    en: 'Registration failed',
    se: 'Registrering misslyckades',
    es: 'Error de registro',
    no: 'Registrering feilet',
    tr: 'Kayıt başarısız oldu',
    dk: 'Registreringen mislykkedes',
  },
  'SignUp.Step.Title': {
    en: (step: string, nrOfSteps: string) => `Create account ${step} of ${nrOfSteps}`,
    se: (step: string, nrOfSteps: string) => `Skapa konto ${step} av ${nrOfSteps}`,
    es: (step: string, nrOfSteps: string) => `Crear cuenta ${step} de ${nrOfSteps}`,
    no: (step: string, nrOfSteps: string) => `Opprett konto ${step} av ${nrOfSteps}`,
    tr: (step: string, nrOfSteps: string) => `${nrOfSteps} hesabının ${step} hesabını oluşturun`,
    dk: (step: string, nrOfSteps: string) => `Opret konto ${step} af ${nrOfSteps}`,
  },
  'VerifyAccount.Title': {
    en: 'Verify your account',
    se: 'Verifiera ditt konto',
    es: 'Verifica tu cuenta',
    no: 'Verifiser kontoen din',
    tr: 'Hesabınızı doğrulayın',
    dk: 'Bekræft din konto',
  },
  'VerifyAccount.Text': {
    en: 'We have sent an SMS to your phone number with a code. Enter the code here:',
    se: 'Vi har skickat ett SMS till ditt mobilnummer med en kod. Skriv in koden här:',
    es: 'Hemos enviado un SMS a su número de teléfono con un código. Ingrese el código aquí:',
    no: 'Vi har sendt en SMS til ditt mobilnummer med en kode. Oppgi koden her:',
    tr: 'Telefon numaranıza kod içeren bir SMS gönderdik. Kodu buraya girin:',
    dk: 'Vi har sendt en SMS til dit telefonnummer med en kode. Indtast koden her:',
  },
  'General.Loading': {
    en: 'Loading...',
    se: 'Laddar...',
    es: 'Cargando...',
    no: 'Laster...',
    tr: 'Yükleniyor...',
    dk: 'Indlæser...',
  },
  'General.OutOfStock': {
    en: 'Out of stock',
    se: 'Slut för dagen',
    es: 'Fuera de stock',
    no: 'Utsolgt',
    tr: 'Stokta yok',
    dk: 'Udsolgt',
  },
  'General.LimitStock': {
    en: "We don't have more in stock",
    se: 'Vi har inte mer i lager',
    es: 'No tenemos más en stock',
    no: 'Vi er dessverre utsolgt',
    tr: 'Stoklarımızda daha fazla yok',
    dk: 'Vi har ikke flere på lager',
  },
  'VerifyAccount.ButtonText': {
    en: 'Verify',
    se: 'Verifiera',
    es: 'Verificar',
    no: 'Verifiser',
    tr: 'Doğrula',
    dk: 'Verificer',
  },
  'VerifyAccount.NewCode': {
    en: 'Send a new code',
    se: 'Skicka en ny kod',
    es: 'Enviar un nuevo código',
    no: 'Be om en ny kode',
    tr: 'Yeni bir kod gönder',
    dk: 'Send en ny kode',
  },
  'VerifyAccount.Modal.CodeSent': {
    en: 'Code sent',
    se: 'Kod skickad',
    es: 'Código enviado',
    no: 'Kode sendt',
    tr: 'Kod gönderildi',
    dk: 'Kode sendt',
  },
  'VerifyAccount.Modal.Text': {
    en: 'A new code has been sent to your phone.',
    se: 'En ny kod har skickats till din telefon.',
    es: 'Se ha enviado un nuevo código a tu teléfono.',
    no: 'EN ny kode har blitt sendt til din telefon.',
    tr: 'Telefonunuza yeni bir kod gönderildi.',
    dk: 'En ny kode er blevet sendt til din telefon.',
  },
  'VerifyAccount.Modal.ButtonText': {
    en: 'Ok',
    se: 'Ok',
    es: 'De acuerdo',
    no: 'Ok',
    tr: 'Tamam',
    dk: 'Okay',
  },
  'VerifyAccount.Error.InvalidCode': {
    en: 'Invalid activation code',
    se: 'Ogiltig aktiveringskod',
    es: 'Código de activación no válido',
    no: 'Ugyldig aktiveringskode',
    tr: 'Geçersiz aktivasyon kodu',
    dk: 'Ugyldig aktiveringskode',
  },
  'InvoiceSignup.Title': {
    en: 'Business Account',
    se: 'Företagskonto',
    es: 'Cuenta empresarial',
    no: 'Bedriftskonto',
    tr: 'İşletme Hesabı',
    dk: 'Erhvervskonto',
  },
  'InvoiceSignup.RegisterFirst': {
    en:
      'In order to apply for a business account you must first register. Click the button below to continue.',
    se:
      'För att ansöka om ett företagskonto måste du först registrera dig. Klicka på knappen nedan för att fortsätta.',
    es:
      'Para solicitar una cuenta empresarial, primero debe registrarse. Haga clic en el botón a continuación para continuar.',
    no:
      'For å søke om en bedriftskonto må du først registrere deg. Trykk på knappen nedenfor for å fortsette.',
    tr:
      'İşletme hesabına başvurabilmek için öncelikle kayıt olmanız gerekmektedir. Devam etmek için aşağıdaki düğmeye tıklayın.',
    dk:
      'For at ansøge om en virksomhedskonto skal du først registrere dig. Klik på knappen nedenfor for at fortsætte.',
  },
  'InvoiceSignup.Terms': {
    se:
      'VILLKOR FÖR FAKTURERING\nGenom att skicka din ansökan godkänner du följande villkor för fakturabetalning. Sedvanlig kreditprövning kan göras.\n\nVi skickar en samlingsfaktura 1 gång per vecka. Det innebär att vi varje måndag sammanställer en faktura som innehåller alla köp gjorda föregående vecka. Betalningsvillkor är 30 dagar.\n\nVi förbehåller oss rätten att stänga kontot och möjlighet till fakturering om krediten missbrukas.',
    en:
      'TERMS FOR INVOICING\nBy submitting your application you accept the following terms for invoicing. Standard credit checks may be made.\n\nWe send a consolidated invoice once a week. This means that we compile an invoice every Monday that contains all purchases made the previous week. Payment terms are 30 days.\n\nWe reserve the right to close the account and the possibility of invoicing if the credit is misused.',
    es:
      'TÉRMINOS PARA FACTURACIÓN\n\nAl enviar su solicitud, acepta los siguientes términos para la facturación. Se pueden realizar controles de crédito estándar.\n\nEnviamos una factura consolidada una vez por semana. Esto significa que compilamos una factura todos los lunes que contienen todas las compras realizadas la semana anterior. Los términos de pago son de 30 días.\n\nNos reservamos el derecho de cerrar la cuenta y la posibilidad de facturación si el crédito se utiliza de manera indebida.',
    no:
      'VILKÅR FOR FAKTURERING\nVed å sende inn en søknad godkjenner du følgende vilkår for fakturering. Kredittsjekk kan bli foretatt.\n\nVi sender en samlefaktura 1 gang i uken. Det innebærer at vi hver mandag sammenstiller en faktura som inneholder alle kjøp gjort i foregående uke. Betalingsvilkår er 30 dager.\n\nVi forbeholder oss retten til å stenge kontoen og mulighet til fakturering dersom kreditt misbrukes.',
    tr:
      'FATURALAMA KOŞULLARI Başvurunuzu göndererek aşağıdaki faturalandırma koşullarını kabul etmiş olursunuz. Standart kredi kontrolleri yapılabilir. Haftada bir kez birleştirilmiş fatura gönderiyoruz. Bu, her Pazartesi, önceki hafta yapılan tüm satın alma işlemlerini içeren bir fatura düzenlediğimiz anlamına gelir. Ödeme vadesi 30 gündür. Kredinin kötüye kullanılması halinde hesabı kapatma ve fatura kesme hakkımız saklıdır.',
    dk: `BETINGELSER FOR FAKTURERING  Ved at indsende din ansøgning accepterer du følgende betingelser for fakturering. Standard kredittjek kan foretages. Vi sender en samlet faktura en gang om ugen. Det betyder, at vi hver mandag udarbejder en faktura, der indeholder alle køb foretaget den foregående uge. Betalingsbetingelser er 30 dage. Vi forbeholder os retten til at lukke kontoen og muligheden for fakturering, hvis kreditten misbruges.`,
  },
  'InvoiceSignup.InfoText': {
    en:
      'Fill out the form below to apply for a business account. We will respond with a confirmation after we have verified your details.',
    se:
      'Fyll i formuläret nedan för att ansöka om ett företagskonto. Vi kommer att svara med en bekräftelse efter att vi har verifierat dina uppgifter.',
    es:
      'Complete el siguiente formulario para solicitar una cuenta empresarial. Le responderemos con una confirmación después de haber verificado sus datos.',
    no:
      'Fyll ut skjemaet nedenfor for å søke om en bedriftskonto. Vi kommer tilbake til deg med en bekreftelse etter å ha verifisert informasjonen din.',
    tr:
      'Ticari hesaba başvurmak için aşağıdaki formu doldurun. Bilgilerinizi doğruladıktan sonra bir onayla yanıt vereceğiz.',
    dk:
      'Udfyld formularen nedenfor for at ansøge om en virksomhedskonto. Vi svarer med en bekræftelse, når vi har bekræftet dine oplysninger.',
  },
  'Error.ERROR_FAIL_VERIFY_PHONE': {
    en: 'The code you provided was not correct. Please try again.',
    se: 'Koden du angav var inte korrekt. Var vänlig försök igen.',
    es: 'El código que proporcionaste no es correcto. Inténtelo de nuevo.',
    no: 'Koden du oppga var ikke korrekt. Vennligst prøv igjen.',
    tr: 'Sağladığınız kod doğru değildi. Lütfen tekrar deneyin.',
    dk: 'Den kode, du angav, var ikke korrekt. Prøv venligst igen.',
  },
  'Error.ERROR_USER_IS_REQUIRED': {
    en: 'You have to be logged in to perform this action.',
    se: 'Du måste vara inloggad för att genomföra detta',
    es: 'Debes haber iniciado sesión para realizar esta acción.',
    no: 'Du må være innlogget for å kunne gjøre dette',
    tr: 'Bu eylemi gerçekleştirmek için oturum açmanız gerekir.',
    dk: 'Du skal være logget ind for at udføre denne handling.',
  },
  'Error.ERROR_INVALID_CREDENTIALS': {
    en: 'The phone number or password you provided is not correct',
    se: 'Telefonnumret eller lösenordet du angivit stämmer inte',
    es: 'El número de teléfono o la contraseña que proporcionó no es correcto',
    no: 'Telefonnummeret eller passordet du har oppgitt stemmer ikke',
    tr: 'Sağladığınız telefon numarası veya şifre doğru değil',
    dk: 'Det telefonnummer eller den adgangskode, du har angivet, er ikke korrekt',
  },
  'Error.USER_ALREADY_EXISTS': {
    en: 'This user already exists. Please try again with another phone number.',
    se: 'Användaren existerar redan. Vänligen försök igen med ett annat telefonnummer.',
    es: 'Este usuario ya existe. Inténtelo de nuevo con otro número de teléfono.',
    no: 'Brukeren eksisterer allerede. Vennligst prøv igjen med et annet telefonnummer.',
    tr: 'Bu kullanıcı zaten mevcut. Lütfen başka bir telefon numarasıyla tekrar deneyin.',
    dk: 'Denne bruger findes allerede. Prøv venligst igen med et andet telefonnummer.',
  },
  'Error.UNEXPECTED_ERROR_OCCURRED': {
    en: 'Something went wrong, please try logging in again',
    se: 'Något har gått fel, vänligen försök logga in igen',
    es: 'Algo salió mal, intenta iniciar sesión de nuevo',
    no: 'En feil har oppstått. Vennligst prøv å logge inn på nytt',
    tr: 'Bir şeyler ters gitti, lütfen tekrar giriş yapmayı deneyin',
    dk: 'Noget gik galt. Prøv venligst at logge ind igen',
  },
  'Error.ERROR_USER_ALREADY_EXISTS': {
    en: 'This user already exists. Please try again with another phone number.',
    se: 'Användaren existerar redan, försök igen med ett annat telefonnummer',
    es: 'Este usuario ya existe. Inténtelo de nuevo con otro número de teléfono.',
    no: 'Brukeren eksisterer allerede. Vennligst prøv igjen med et annet telefonnummer.',
    tr: 'Bu kullanıcı zaten mevcut. Lütfen başka bir telefon numarasıyla tekrar deneyin.',
    dk: 'Denne bruger findes allerede. Prøv venligst igen med et andet telefonnummer.',
  },
  'Error.ERROR_NOT_VALID_PHONE_NUMBER': {
    en:
      'The phone number you entered is not valid. Please entered a valid phone number. It should start with a "+" and a country code.',
    se:
      'Telefonnumret du angivit är inte giltigt. Det måste börja med ett plustecken och din landskod (t.ex. +46)',
    es:
      'El número de teléfono que ingresó no es válido. Introduzca un número de teléfono válido. Debe comenzar con un "+" y un código de país.',
    no:
      'Telefonnummeret er ikke gyldig. Det må begynne med et plusstegn og landskoden (f. eks. +47)',
    tr:
      'Girdiğiniz telefon numarası geçerli değil. Lütfen geçerli bir telefon numarası girin. "+" ve ülke koduyla başlamalıdır.',
    dk:
      'Det telefonnummer, du indtastede, er ikke gyldigt. Indtast venligst et gyldigt telefonnummer. Det skal starte med et "+" og en landekode.',
  },
  'Error.PAYMENT_DECLINED_CREDIT_CARD_FUNDS': {
    en: 'We could not handle your payment. Please verify your balance.',
    se: 'Medges Ej. Vänligen försök igen med en annan betalningsmetod.',
    es: 'No pudimos aceptar su pago. Por favor, verifique su saldo.',
    no: 'Vi kunne ikke gjennomføre betaling. Vennligst kontroller saldo.',
    tr: 'Ödemenizi gerçekleştiremedik. Lütfen bakiyenizi doğrulayın.',
    dk: 'Vi kunne ikke håndtere din betaling. Bekræft venligst din saldo.',
  },
  'Error.HANDLE_ORDER_AFTER_PAYMENT_ERROR': {
    en:
      'An error occurred, the money that has been withdrawn will be refunded within 3 banking days. Please try again.',
    se:
      'Ett oväntat fel inträffade. Pengar som har dragits kommer återbetalas inom 3 arbetsdagar. Vänligen försök igen.',
    es:
      'Se ha producido un error, el dinero que ha sido retirado será reembolsado dentro de los siguientes 3 días hábiles. Por favor inténtalo de nuevo.',
    no:
      'En feil har oppstått. Penger som har blitt trukket blir refundert innen 3 arbeidsdager. Vennligst prøv igjen.',
    tr: 'Bir hata oluştu, çekilen para 3 iş günü içinde iade edilecektir. Lütfen tekrar deneyin.',
    dk: 'Der opstod en fejl, de hævede penge refunderes inden for 3 bankdage. Prøv venligst igen.',
  },
  'Error.INTERNAL_SERVER_ERROR': {
    en: 'Something went wrong. Please try again later.',
    se: 'Något gick fel. Var god och prova igen senare.',
    es: 'Algo salió mal. Por favor inténtelo de nuevo más tarde.',
    no: 'En feil har oppstått. Vennligst prøv igjen senere.',
    tr: 'Bir şeyler ters gitti. Lütfen daha sonra tekrar deneyin.',
    dk: 'Noget gik galt. Prøv venligst igen senere.',
  },
  'Error.ERROR_USER_NOT_FOUND': {
    en: 'We do not recognize this email address. Check if it may be misspelled and try again.',
    se:
      'Vi känner inte igen den här e-postadressen. Kontrollera om den kan vara felstavad och försök igen.',
    es:
      'No reconocemos esta dirección de correo electrónico. Compruebe si puede estar mal escrito y vuelva a intentarlo.',
    no:
      'Vi kjenner ikke igjen denne e-postadressen. Kontroller at det er skrevet riktig, og prøv igjen.',
    tr:
      'Bu e-posta adresini tanımıyoruz. Yanlış yazılmış olup olmadığını kontrol edin ve tekrar deneyin.',
    dk:
      'Vi genkender ikke denne e-mailadresse. Tjek, om det kan være stavet forkert, og prøv igen.',
  },
  'Error.ValidationFailed': {
    en: 'Oops!',
    se: 'Oops!',
    es: 'Ups!',
    no: 'Oops!',
    tr: 'Hata!',
    dk: 'Ups!',
  },
  'SignUp.Error.Name': {
    en: 'First and last name required',
    se: 'Vänligen skriv in både för och efternamn',
    es: 'Nombre y apellido requeridos',
    no: 'Vennligst skriv inn både for- og etternavn',
    tr: 'Ad ve soyad gerekli',
    dk: 'For- og efternavn påkrævet',
  },
  'SignUp.Error.Phone': {
    en: 'The number should begin with +',
    se: 'Numret måste börja med +',
    es: 'El número debe comenzar con +',
    no: 'Nummeret må begynne med +',
    tr: 'Numara + ile başlamalıdır',
    dk: 'Tallet skal begynde med +',
  },
  'SignUp.Error.Email': {
    en: 'The email address is incorrect',
    se: 'Mailadressen är inte korrekt',
    es: 'La dirección de correo electrónico es incorrecta',
    no: 'E-postadressen er ugyldig',
    tr: 'E-posta adresi yanlış',
    dk: 'E-mailadressen er forkert',
  },
  'SignUp.Error.Password': {
    en: 'Password has to be at least 4 characters',
    se: 'Lösenordet måste vara minst 4 bokstäver',
    es: 'La contraseña debe tener al menos 4 caracteres',
    no: 'Passordet må være minst 4 bokstaver',
    tr: 'Şifre en az 4 karakterden oluşmalıdır',
    dk: 'Adgangskoden skal være på mindst 4 tegn',
  },
  'SignUp.Error.PasswordMatch': {
    en: 'Passwords do not match',
    se: 'Lösenorden matchar inte',
    es: 'Las contraseñas no coinciden',
    no: 'Passordene matcher ikke',
    tr: 'Şifreler eşleşmiyor',
    dk: 'Adgangskoder stemmer ikke overens',
  },
  'SignUp.Error.UnexpectedErrorOccurred': {
    en: 'An unexpected error occurred. Please try again',
    se: 'Ett oväntat fel inträffade. Försök gärna igen.',
    es: 'Se ha producido un error inesperado. Inténtelo de nuevo.',
    no: 'En feil har oppstått. Vennligst prøv igjen.',
    tr: 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin',
    dk: 'Der opstod en uventet fejl. Prøv venligst igen',
  },
  'Signin.Title': {
    en: 'Log in',
    se: 'Logga in',
    es: 'Inicia sesión',
    no: 'Logg inn',
    tr: 'Giriş yapmak',
    dk: 'Log ind',
  },
  'Signin.Password': {
    en: 'Password',
    se: 'Lösenord',
    es: 'Contraseña',
    no: 'Passord',
    tr: 'Şifre',
    dk: 'Adgangskode',
  },
  'Signin.Password.Placeholder': {
    en: 'Enter your password',
    se: 'Fyll i ditt lösenord',
    es: 'Introduzca su contraseña',
    no: 'Fyll inn passordet ditt',
    tr: 'Şifrenizi girin',
    dk: 'Indtast din adgangskode',
  },
  'Signin.Next': {
    en: 'Next',
    se: 'Nästa',
    es: 'Siguiente',
    no: 'Neste',
    tr: 'Sonraki',
    dk: 'Næste',
  },
  'Signin.ForgotPassword': {
    en: 'Forgot password',
    se: 'Glömt lösenord',
    es: 'Olvidé mi contraseña',
    no: 'Glemt passord',
    tr: 'Parolanızı mı unuttunuz',
    dk: 'Glemt adgangskode',
  },
  'Signin.Modal.Title': {
    en: 'Sign in or create an account',
    se: 'Logga in eller skapa konto',
    es: 'Iniciar sesión o crear una cuenta',
    no: 'Logg inn eller opprett konto',
    tr: 'Oturum açın veya bir hesap oluşturun',
    dk: 'Log ind eller opret en konto',
  },
  'Signin.Modal.Button.Create': {
    en: 'Create account',
    se: 'Skapa konto',
    es: 'Crear cuenta',
    no: 'Opprett konto',
    tr: 'Hesap oluştur',
    dk: 'Opret konto',
  },
  'Signin.Modal.Button.Signin': {
    en: 'Log in',
    se: 'Logga in',
    es: 'Inicia sesión',
    no: 'Logg inn',
    tr: 'Giriş yap',
    dk: 'Log ind',
  },
  'SignIn.Error.Failed': {
    en: 'Login failed',
    se: 'Inloggning misslyckades',
    es: 'Error de inicio de sesión',
    no: 'Innlogging feilet',
    tr: 'Giriş başarısız oldu',
    dk: 'Login mislykkedes',
  },
  'Consent.Banner.Title': {
    en: 'We use cookies',
    se: 'Vi använder kakor',
    es: 'Nosotros usamos cookies',
    no: 'Vi bruker cookies',
    tr: 'Çerezleri kullanıyoruz',
    dk: 'Vi bruger cookies',
  },
  'Consent.Banner.ReadMore': {
    en: 'Read more and configure.',
    se: 'Läs mer och konfigurera.',
    es: 'Leer más y configurar',
    no: 'Les mer og konfigurer',
    tr: 'Daha fazlasını okuyun ve yapılandırın.',
    dk: 'Læs mere og konfigurer.',
  },
  'Consent.Banner.Button.Agree': {
    en: 'Agree',
    se: 'Acceptera',
    es: 'Aceptar',
    no: 'Aksepter',
    tr: 'Kabul et',
    dk: 'Enig',
  },
  'Consent.Banner.Button.Deny': {
    en: 'Deny',
    se: 'Neka',
    es: 'Negar',
    no: 'Avvis',
    tr: 'Reddet',
    dk: 'Afvis',
  },
  'ForgotPassword.Text': {
    en: 'Forgot password',
    se: 'Glömt lösenord',
    es: 'Olvidé mi contraseña',
    no: 'Glemt passord',
    tr: 'Parolanızı mı unuttunuz',
    dk: 'Glemt adgangskode',
  },
  'ForgotPassword.Title': {
    en: (step: string, nrOfSteps: string) => `Reset password ${step} of ${nrOfSteps}`,
    se: (step: string, nrOfSteps: string) => `Återställ lösenord ${step} av ${nrOfSteps}`,
    es: (step: string, nrOfSteps: string) => `Restablecer contraseña ${step} of ${nrOfSteps}`,
    no: (step: string, nrOfSteps: string) => `Nullstill passord ${step} av ${nrOfSteps}`,
    tr: (step: string, nrOfSteps: string) => `Şifreyi sıfırla ${step} / ${nrOfSteps}`,
    dk: (step: string, nrOfSteps: string) => `Nulstil adgangskoden ${step} af ${nrOfSteps}`,
  },
  'ForgotPassword.NewPassword': {
    en: 'Enter new password',
    se: 'Skriv in nytt lösenord',
    es: 'Introduzca una nueva contraseña',
    no: 'Skriv inn et nytt passord',
    tr: 'Yeni şifreyi girin',
    dk: 'Indtast ny adgangskode',
  },
  'ForgotPassword.Code': {
    en: 'We have sent an SMS to your phone number with a code. Enter the code here:',
    se: 'Vi har skickat ett SMS till ditt mobilnummer med en kod. Skriv in koden här:',
    es: 'Hemos enviado un SMS a su número de teléfono con un código. Ingrese el código aquí:',
    no: 'Vi har sendt en SMS til ditt mobilnummer med en kode. Skriv inn koden her:',
    tr: 'Telefon numaranıza kod içeren bir SMS gönderdik. Kodu buraya girin:',
    dk: 'Vi har sendt en SMS til dit telefonnummer med en kode. Indtast koden her:',
  },
  'ForgotPassword.Code.Placeholder': {
    en: 'Input your code',
    se: 'Fyll din kod',
    es: 'Introducir en el código',
    no: 'Skriv inn kode',
    tr: 'Kodunuzu girin',
    dk: 'Indtast din kode',
  },
  'ForgotPassword.Error.Code': {
    en: 'The code is wrong',
    se: 'Koden är fel',
    es: 'El código es incorrecto',
    no: 'Koden er feil',
    tr: 'Kod yanlış',
    dk: 'Koden er forkert',
  },
  'ForgotPassword.Error.Failed': {
    en: 'Reset password failed',
    se: 'Återställ lösenord misslyckades',
    es: 'Error al restablecer la contraseña',
    no: 'Nullstilling av passord mislyktes',
    tr: 'Şifre sıfırlama başarısız oldu',
    dk: 'Nulstilling af adgangskode mislykkedes',
  },
  'Home.Categories.More': {
    en: 'View All',
    se: 'Visa fler',
    es: 'Ver más',
    no: 'Vis mer',
    tr: 'Tümünü Görüntüle',
    dk: 'Se alle',
  },
  'Home.SeeMenu': {
    en: 'View the whole menu',
    se: 'Se hela menyn',
    es: 'Ver el menú completo',
    no: 'Se hele menyen',
    tr: 'Tüm menüyü görüntüle',
    dk: 'Se hele menuen',
  },

  'OrderHistory.Title': {
    en: 'Order history',
    se: 'Orderhistorik',
    es: 'Historial de pedidos',
    no: 'Ordrehistorikk',
    tr: 'Sipariş geçmişi',
    dk: 'Ordrehistorik',
  },
  'OrderHistory.OrderId': {
    en: 'Order#',
    se: 'Order#',
    es: 'Número de pedido',
    no: 'Ordre #',
    tr: 'Sipariş#',
    dk: 'Bestil#',
  },
  'OrderHistory.ServiceType': {
    en: 'Service type',
    se: 'Servicetyp',
    es: 'Tipo de servicio',
    no: 'Servicetype',
    tr: 'Hizmet türü',
    dk: 'Servicetype',
  },
  'OrderHistory.Date': {
    en: 'Date',
    se: 'Datum',
    es: 'Fecha',
    no: 'Dato',
    tr: 'Tarih',
    dk: 'Dato',
  },
  'OrderHistory.Date.Scheduled': {
    en: 'Scheduled time',
    se: 'Tid för upphämtning',
    es: 'Hora programada',
    no: 'Hentetidspunkt',
    tr: 'Planlanan zaman',
    dk: 'Planlagt tid',
  },
  'OrderHistory.Total': {
    en: 'Total',
    se: 'Total',
    es: 'Total',
    no: 'Total',
    tr: 'Toplam',
    dk: 'Total',
  },
  'OrderHistory.Tips': {
    en: 'Tips',
    se: 'Dricks',
    es: 'Propina',
    no: 'Tips',
    tr: 'Bahşiş',
    dk: 'Drikkepenge',
  },
  'OrderHistory.VAT': {
    en: 'VAT',
    se: 'moms',
    es: 'IVA',
    no: 'MVA',
    tr: 'KDV',
    dk: 'moms',
  },
  'OrderHistory.Total.VAT': {
    en: 'Total VAT',
    se: 'Totalsumma moms',
    es: 'IVA total',
    no: 'Total MVA',
    tr: 'Toplam KDV',
    dk: 'Samlet moms',
  },
  'OrderHistory.Total.Exclude.VAT': {
    en: 'Total excluding VAT',
    se: 'Pris exklusive moms',
    es: 'Total sin IVA',
    no: 'Total eks. MVA',
    tr: 'KDV hariç toplam',
    dk: 'I alt eksklusiv moms',
  },
  'OrderHistory.Store': {
    en: 'Store',
    se: 'Butik',
    es: 'Tienda',
    no: 'Butikk',
    tr: 'Mağaza',
    dk: 'Butik',
  },
  'OrderHistory.OrgNr': {
    en: 'Organisation number',
    se: 'Orgnummer',
    es: 'Número de organización',
    no: 'Organisasjonsnummer',
    tr: 'Organizasyon numarası',
    dk: 'Organisationsnummer',
  },
  'OrderHistory.Discount': {
    en: 'Discount',
    se: 'Rabatt',
    es: 'Descuento',
    no: 'Rabatt',
    tr: 'İndirim',
    dk: 'Rabat',
  },
  'Order.Status': {
    en: 'Status',
    se: 'Status',
    es: 'Estado',
    no: 'Status',
    tr: 'Durum',
    dk: 'Status',
  },
  'Order.Status.Rejected': {
    en: 'Rejected',
    se: 'Nekad',
    es: 'Rechazado',
    no: 'Avvist',
    tr: 'Reddedildi',
    dk: 'Afvist',
  },
  'Order.Status.Accepted': {
    en: 'Accepted',
    se: 'Accepterad',
    es: 'Aceptado',
    no: 'Akseptert',
    tr: 'Kabul edildi',
    dk: 'Accepteret',
  },
  'Order.Status.Ready': {
    en: 'Ready',
    se: 'Redo',
    es: 'Listo',
    no: 'Klar',
    tr: 'Hazır',
    dk: 'Klar til afhentning',
  },
  'Orderstatus.Pending.Title': {
    en: 'Order received',
    se: 'Order mottagen',
    es: 'Pedido recibido',
    no: 'Ordre mottatt',
    tr: 'Sipariş alındı',
    dk: 'Bestilling modtaget',
  },
  'Orderstatus.Pending.Text': {
    en: 'We will get back to you soon',
    se: 'Vi besvarar dig inom kort',
    es: 'Nos pondremos en contacto con usted pronto',
    no: 'Vi kommer straks tilbake til deg',
    tr: 'Yakında size geri döneceğiz',
    dk: 'Vi vender snart tilbage til dig',
  },
  'Orderstatus.Preparing.Title': {
    en: 'Preparing',
    se: 'Förbereder',
    es: 'Preparando',
    no: 'Forbereder',
    tr: 'Hazırlanıyor',
    dk: 'Forbereder',
  },
  'Orderstatus.Preparing.Text': {
    en: 'Estimated time is',
    se: 'Uppskattad tid',
    es: 'El tiempo estimado es',
    no: 'Forventet tid er',
    tr: 'Tahmini süre:',
    dk: 'Estimeret tid er',
  },
  'Orderstatus.Ready.Title': {
    en: 'Ready for pickup',
    se: 'Redo för upphämtning',
    es: 'Listo para recoger',
    no: 'Klar for henting',
    tr: 'Alım için hazır',
    dk: 'Klar til afhentning',
  },
  'Orderstatus.Ready.Text.I': {
    en: 'Enter order number',
    se: 'Ange ordernummer',
    es: 'Introduzca el número de pedido',
    no: 'Oppgi ordrenummer',
    tr: 'Sipariş numarasını girin',
    dk: 'Indtast ordrenummer',
  },
  'OrderStatus.Ready.Text.II': {
    en: 'at checkout',
    se: 'i kassan',
    es: 'al finalizar la compra',
    no: 'i kassen',
    tr: 'Ödeme sırasında',
    dk: 'ved kassen',
  },
  'Orderstatus.Completed.Title': {
    en: 'Completed',
    se: 'Färdigställd',
    es: 'Completado',
    no: 'Ferdig',
    tr: 'Tamamlanmış',
    dk: 'Afsluttet',
  },
  'Orderstatus.Completed.Text': {
    en: 'Click here for your receipt',
    se: 'Klicka här för ditt kvitto',
    es: 'Haga clic aquí para su recibo',
    no: 'Klikk her for kvittering',
    tr: 'Makbuzunuz için burayı tıklayın',
    dk: 'Klik her for din kvittering',
  },
  'Orderstatus.Rejected.Title': {
    en: 'Sorry, we can not accept your order.',
    se: 'Tyvärr kunde vi inte ta emot din order.',
    es: 'Lo sentimos, no podemos aceptar su pedido.',
    no: 'Vi kan dessverre ikke ta imot bestillingen din.',
    tr: 'Üzgünüz, siparişinizi kabul edemiyoruz.',
    dk: 'Beklager, vi kan ikke acceptere din ordre.',
  },
  'Orderstatus.Rejected.Closed': {
    en: 'We are closing soon and can not complete your order on time. Try ordering tomorrow.',
    se: 'Vi stänger snart och hinner inte slutföra din order i tid. Prova beställa imorgon.',
    es:
      'Estamos cerrando pronto y no podemos completar su pedido a tiempo. Intente ordenar mañana.',
    no:
      'Vi stenger snart og rekker dessverre ikke å fullføre bestillingen din i tide. Velkommen ved en annen anledning.',
    tr:
      'Yakında kapanıyoruz ve siparişinizi zamanında tamamlayamıyoruz. Yarın sipariş vermeyi deneyin.',
    dk: 'Vi lukker snart og kan ikke gennemføre din ordre til tiden. Prøv at bestille i morgen.',
  },
  'OrderStatus.Rejected.Text': {
    en: 'We apologise but the restaurant did not respond in time. Try again.',
    se: 'Vi ber om ursäkt men restaurangen svarade inte i tid. Prova igen.',
    es: 'Lo sentimos, el restaurante no respondió a tiempo. Vuelve a intentarlo.',
    no: 'Vi beklager, men restauranten svarte ikke i tide. Vennligst prøv igjen.',
    tr: 'Özür dileriz ancak restoran zamanında yanıt vermedi. Tekrar deneyin.',
    dk: 'Vi beklager, men restauranten svarede ikke i tide. Prøv igen.',
  },
  'Orderstatus.Rejected.BestRegards': {
    en: (company: string) => `Best regards ${company}.`,
    se: (company: string) => `Vänliga hälsningar ${company}.`,
    es: (company: string) => `Saludos ${company}.`,
    no: (company: string) => `Vennlig hilsen ${company}.`,
    tr: (company: string) => `Saygılarımızla ${company}.`,
    dk: (company: string) => `Venlig hilsen ${company}.`,
  },
  'Orderstatus.Soon': {
    en: 'soon',
    se: 'snart',
    es: 'pronto',
    no: 'snart',
    tr: 'En kısa zamanda',
    dk: 'snart',
  },
  'Paymentmethods.Title': {
    en: 'Payment methods',
    se: 'Betalmedel',
    es: 'Formas de pago',
    no: 'Betalingsmiddel',
    tr: 'Ödeme yöntemleri',
    dk: 'Betalingsmetoder',
  },
  'Paymentmethods.Add': {
    en: 'Add payment method',
    se: 'Lägg till betalningsmedel',
    es: 'Añadir método de pago',
    no: 'Legg til betalingsmiddel',
    tr: 'Ödeme yöntemi ekle',
    dk: 'Tilføj betalingsmetode',
  },
  'Membership.Title': {
    en: 'Membership',
    se: 'Medlemskort',
    es: 'Membresía',
    no: 'Medlemskort',
    tr: 'Üyelik',
    dk: 'Medlemskab',
  },
  'Membership.Points': {
    en: 'Your points',
    se: 'Dina poäng',
    es: 'Tus puntos',
    no: 'Dine poeng',
    tr: 'Puanlarınız',
    dk: 'Dine point',
  },
  'Membership.Level.Title': {
    en: 'Membership level',
    se: 'Medlemsnivå',
    es: 'Nivel de membresía',
    no: 'Medlemsnivå',
    tr: 'Üyelik düzeyi',
    dk: 'Medlemsniveau',
  },
  'Membership.Number': {
    en: 'Membership number',
    se: 'Medlemsnummer',
    es: 'Número de membresía',
    no: 'Medlemsnummer',
    tr: 'Üyelik numarası',
    dk: 'Medlemsnummer',
  },
  'Membership.Benefits': {
    en: 'membership benefits',
    se: 'medlemsförmåner',
    es: 'beneficios de membresía',
    no: 'medlemsfordeler',
    tr: 'Üyelik avantajları',
    dk: 'medlemsfordele',
  },
  'Membership.Infotext': {
    // maybe this should not be hardcoded
    en: '1 krona spent means one point in the membership. Below we have our membership levels.',
    se: '1 spenderad krona innebär en poäng i medlemskapet. Här under har vi  våra medlemsnivåer.',
    es:
      '1 Euro significan diez puntos en la membresía. A continuación tenemos nuestros niveles de membresía.',
    no: '1 krone brukt gir ett poeng i medlemskapet. Her ser du våre medlemsnivåer.',
    tr:
      'Harcadığınız 1 kron, üyelikte 1 puan anlamına gelir. Aşağıda üyelik seviyelerimiz bulunmaktadır.',
    dk: '1 krone brugt betyder et point i medlemstallet. Nedenfor har vi vores medlemsniveauer.',
  },
  'Membership.Level': {
    en: (step: string, nrOfSteps: string) => `Level ${step} / ${nrOfSteps}`,
    se: (step: string, nrOfSteps: string) => `Nivå ${step} av ${nrOfSteps}`,
    es: (step: string, nrOfSteps: string) => `Nivel ${step} de ${nrOfSteps}`,
    no: (step: string, nrOfSteps: string) => `Nivå ${step} av ${nrOfSteps}`,
    tr: (step: string, nrOfSteps: string) => `Seviye ${step} / ${nrOfSteps}`,
    dk: (step: string, nrOfSteps: string) => `Niveau ${step} / ${nrOfSteps}`,
  },
  'Membership.discount': {
    en: 'Discount on the food check',
    se: 'Rabatt på matnotan',
    es: 'Descuento en la cuenta de alimentos',
    no: 'Rabatt på mat',
    tr: 'Yemek kontrolünde indirim',
    dk: 'Rabat på maden',
  },
  'Membership.TotalPoints': {
    en: 'Points',
    se: 'Antal poäng',
    es: 'Puntos',
    no: 'Antall poeng',
    tr: 'Puanlar',
    dk: 'Points',
  },
  'Membership.Member': {
    en: 'Member',
    se: 'Medlem',
    es: 'Miembro',
    no: 'Medlem',
    tr: 'Üye',
    dk: 'Medlem',
  },
  'News.Title': {
    en: 'News',
    se: 'Nyheter',
    es: 'Noticias',
    no: 'Nyheter',
    tr: 'Yenilikler',
    dk: 'Nyheder',
  },
  'News.Readmore': {
    en: 'Read more',
    se: 'Läs mer',
    es: 'Leer más',
    no: 'Les mer',
    tr: 'Devamını oku',
    dk: 'Læs mere',
  },
  'News.UpdatedAt': {
    en: 'updated at',
    se: 'uppdaterad',
    es: 'actualizado en',
    no: 'oppdatert den',
    tr: 'Şu tarihte güncellendi:',
    dk: 'opdateret kl',
  },
  'ActivateCode.Title': {
    en: 'Activate code',
    se: 'Aktivera kod',
    es: 'Activar código',
    no: 'Aktiver kode',
    tr: 'Kodu etkinleştir',
    dk: 'Aktiver kode',
  },
  'ActivateCode.Heading': {
    en: 'Activate the code by filling out the form below.',
    se: 'Aktivera koden genom fylla i formuläret nedan.',
    es: 'Activa el código rellenando el siguiente formulario.',
    no: 'Aktiver koden ved å fylle ut skjemaet nedenfor.',
    tr: 'Aşağıdaki formu doldurarak kodu etkinleştirin.',
    dk: 'Aktiver koden ved at udfylde formularen nedenfor.',
  },
  'ActivateCode.Text': {
    en:
      'We offer all our members discounts or offers. Unlock membership levels or other discounts.',
    se:
      'Vi erbjuder alla våra medlemmar rabatter eller erbjudanden. Lås upp medlemsnivåer eller andra rabatter.',
    es:
      'Ofrecemos a todos nuestros miembros ofertas o descuentos. Desbloquea niveles de membresía.',
    no:
      'Vi tilbyr alle våre medlemmer rabatter eller tilbud. Lås opp medlemsnivåer eller andre rabatter.',
    tr:
      'Tüm üyelerimize indirim veya teklifler sunuyoruz. Üyelik seviyelerinin veya diğer indirimlerin kilidini açın.',
    dk:
      'Vi tilbyder alle vores medlemmer rabatter eller tilbud. Lås op for medlemsniveauer eller andre rabatter.',
  },
  'ActivateCode.Code': {
    en: 'Code',
    se: 'Kod',
    es: 'Código',
    no: 'Kode',
    tr: 'Kod',
    dk: 'Kode',
  },
  'ActivateCode.Placeholder': {
    en: 'Enter your code',
    se: 'Fyll i din kod',
    es: 'Introduce tu código',
    no: 'Fyll inn din kode',
    tr: 'Kodunuzu girin',
    dk: 'Indtast din kode',
  },
  'ActivateCode.ButtonText': {
    en: 'Activate',
    se: 'Aktivera',
    es: 'Activar',
    no: 'Aktiver',
    tr: 'Etkinleştir',
    dk: 'Aktiver',
  },
  'Terms.Title': {
    en: 'Terms',
    se: 'Villkor',
    es: 'Términos',
    no: 'Vilkår',
    tr: 'Şartlar',
    dk: 'Vilkår',
  },
  'Terms.EmailButton': {
    en: 'Email us',
    se: 'Maila oss',
    es: 'Envíanos un correo electrónico',
    no: 'Send oss en e-post',
    tr: 'Bize e-posta gönderin',
    dk: 'Email os',
  },
  'StoreList.Title': {
    en: 'Choose a restaurant',
    se: 'Välj restaurang',
    es: 'Elige un restaurante',
    no: 'Velg restaurant',
    tr: 'Bir restoran seçin',
    dk: 'Vælg en restaurant',
  },
  'StoreList.FindPosition': {
    en: 'Find my location',
    se: 'Hitta min position',
    es: 'Encuentra mi ubicación',
    no: 'Finn min posisjon',
    tr: 'Konumumu bul',
    dk: 'Find min placering',
  },
  'StoreList.Denied.Title': {
    en: 'Location access denied',
    se: 'Platsdelning nekad',
    es: 'Acceso a la ubicación denegado',
    no: 'Lokasjonsdeling avvist',
    tr: 'Konum erişimi reddedildi',
    dk: 'Placeringsadgang nægtet',
  },
  'StoreList.Denied.Text': {
    en:
      'You need to accept the location access to see the closest restaurant. Go to the app in the phone settings to change this.',
    se:
      'Du behöver godkänna platsåtkomst för att kunna se närmsta restaurang. Gå till appen under telefonens inställningar för att ändra detta.',
    es:
      'Debe aceptar el acceso a la ubicación para ver el restaurante más cercano. Vaya a la aplicación en la configuración del teléfono para cambiar esto.',
    no:
      'Du må akseptere deling av lokasjon for å kunne se nærmeste restaurant. Dette endres i telefonens innstillinger.',
    tr:
      'En yakın restoranı görmek için konum erişimini kabul etmeniz gerekir. Bunu değiştirmek için telefon ayarlarında uygulamaya gidin.',
    dk:
      'Du skal acceptere lokationsadgangen for at se den nærmeste restaurant. Gå til appen i telefonindstillingerne for at ændre dette.',
  },
  'StoreList.Denied.ButtonText': {
    en: 'OK',
    se: 'OK',
    es: 'Bien',
    no: 'OK',
    tr: 'TAMAM',
    dk: 'OK',
  },
  'StoreList.Today': {
    en: 'Today',
    se: 'Idag',
    es: 'Hoy',
    no: 'I dag',
    tr: 'Bugün',
    dk: 'I dag',
  },
  'StoreList.Modal.TakeAway': {
    en: 'Take away',
    se: 'Avhämtning',
    es: 'Recoger',
    no: 'Take away',
    tr: 'Al-Git',
    dk: 'Take away',
  },
  'StoreList.Modal.EatHere': {
    en: 'Eat here',
    se: 'Äta här',
    es: 'Comer aquí',
    no: 'Spise her',
    tr: 'Burada yiyin',
    dk: 'Spis her',
  },
  'StoreList.Modal.TakeAway.Text': {
    en: 'Skip the line and collect your order',
    se: 'Gå förbi kön och hämta ut din mat.',
    es: 'Sáltate la cola y recoge tu pedido.',
    no: 'Slipp forbi køen og hent bestillingen din',
    tr: 'Sırayı atla ve siparişini al',
    dk: 'Spring køen over og afhent din ordre',
  },
  'StoreList.Modal.EatHere.Text': {
    en: 'Order here and enjoy it in the restaurant.',
    se: 'Beställ här och njut på restuarangen. ',
    es: 'Ordene aquí y disfrútelo en el restaurante. ',
    no: 'Bestill her og spis i restauranten',
    tr: 'Buradan sipariş verin ve restoranda keyfini çıkarın.',
    dk: 'Bestil her og nyd det i restauranten.',
  },
  'Menu.Title': {
    en: 'Menu',
    se: 'Meny',
    es: 'Menú',
    no: 'Meny',
    tr: 'Menü',
    dk: 'Menu',
  },
  'Menu.Choose': {
    en: 'Choose',
    se: 'Välj',
    es: 'Elegir',
    no: 'Velg',
    tr: 'Seç',
    dk: 'Vælg',
  },
  'Item.ChooseOption': {
    en: 'Choose an option',
    se: 'Välj ett alternativ',
    es: 'Hazlo a tu gusto',
    no: 'Velg et alternativ',
    tr: 'Bir seçenek seçin',
    dk: 'Vælg en mulighed',
  },
  'Item.Allergens': {
    en: 'Allergens',
    se: 'Allergener',
    es: 'Alérgenos',
    no: 'Allergener',
    tr: 'Alerjenler',
    dk: 'Allergener',
  },
  'Item.ChooseOption.Multiple': {
    en: 'Choose multiple options',
    se: 'Välj flera alternativ',
    es: 'Elija varias opciones',
    no: 'Velg flere alternativer',
    tr: 'Birden fazla seçenek seçin',
    dk: 'Vælg flere muligheder',
  },
  'Item.NoExtra': {
    en: 'No extras',
    se: 'Inget tillbehör',
    es: 'Sin extras',
    no: 'Ingen tilbehør',
    tr: 'Ekstra yok',
    dk: 'Ingen ekstraudstyr',
  },
  'Item.Button.Add': {
    en: 'Add',
    se: 'Lägg till',
    es: 'Añadir al pedido',
    no: 'Legg til',
    tr: 'Ekle',
    dk: 'Tilføj',
  },
  'Item.Button.RequiredVariations': {
    en: 'Finalize your order',
    se: 'Välj Tillbehör',
    es: 'Elige tus opciones',
    no: 'Velg tilbehør',
    tr: 'Siparişinizi tamamlayın',
    dk: 'Afslut din bestilling',
  },
  'Item.Button.Signin': {
    en: 'Sign in',
    se: 'Logga in',
    es: 'Inicia sesión',
    no: 'Logg inn',
    tr: 'Kayıt ol',
    dk: 'Log ind',
  },
  'Item.Button.Error': {
    en: 'Error',
    se: 'Fel',
    es: 'Error',
    no: 'Feil',
    tr: 'Hata',
    dk: 'Fejl',
  },
  'Item.Next': {
    en: 'Next step',
    se: 'Nästa steg',
    es: 'Siguiente paso',
    no: 'Neste steg',
    tr: 'Sonraki adım',
    dk: 'Næste skridt',
  },
  'Item.Allergen.CELERY': {
    en: 'Celery allergy',
    se: 'Selleriallergi',
    es: 'Alergia a la cebolla',
    no: 'Selleriallergi',
    tr: 'Kereviz alerjisi',
    dk: 'Selleri allergi',
  },
  'Item.Allergen.CRUSTACEANS': {
    en: 'Crustaceans allergy',
    se: 'Kräftdjursallergi',
    es: 'Alergia a los crustáceos',
    no: 'Skalldyrallergi',
    tr: 'Kabuklulara alerji',
    dk: 'Krebsdyrallergi',
  },
  'Item.Allergen.EGGS': {
    en: 'Egg allergy',
    se: 'Äggallergi',
    es: 'Alergia a los huevos',
    no: 'Eggallergi',
    tr: 'Yumurta alerjisi',
    dk: 'Æggeallergi',
  },
  'Item.Allergen.FISH': {
    en: 'Fish allergy',
    se: 'Fiskallergi',
    es: 'Alergia al pescado',
    no: 'Fiskeallergi',
    tr: 'Balık alerjisi',
    dk: 'Fiskeallergi',
  },
  'Item.Allergen.GLUTEN': {
    en: 'Gluten allergy',
    se: 'Glutenallergi',
    es: 'Alergia al gluten',
    no: '',
    tr: 'Gluten alerjisi',
    dk: 'Gluten allergi',
  },
  'Item.Allergen.LUPIN': {
    en: 'Lupin allergy',
    se: 'Lupinallergi',
    es: 'Alergia al lupino',
    no: 'Luppinallergi',
    tr: 'Acı bakla alerjisi',
    dk: 'Lupinallergi',
  },
  'Item.Allergen.MILK': {
    en: 'Milk allergy',
    se: 'Mjölkallergi',
    es: 'Alergia a la leche',
    no: 'Melkeallergi',
    tr: 'Süt alerjisi',
    dk: 'Mælkeallergi',
  },
  'Item.Allergen.MOLLUSCS': {
    en: 'Molluscs allergy',
    se: 'Blötdjursallergi',
    es: 'Alergia a los moluscos',
    no: 'Bløtdyrallergi',
    tr: 'Yumuşakça alerjisi',
    dk: 'Bløddyrsallergi',
  },
  'Item.Allergen.MUSTARD': {
    en: 'Mustard allergy',
    se: 'Senapsallergi',
    es: 'Alergia a la mostaza',
    no: 'Sennepsallergi',
    tr: 'Hardal alerjisi',
    dk: 'Sennepsallergi',
  },
  'Item.Allergen.NUTS': {
    en: 'Nut allergy',
    se: 'Nötallergi',
    es: 'Alergia a los frutos secos',
    no: 'Nøtteallergi',
    tr: 'Fındık alerjisi',
    dk: 'Nøddeallergi',
  },
  'Item.Allergen.PEANUTS': {
    en: 'Peanut allergy',
    se: 'Jordnötsallergi',
    es: 'Alergia a los cacahuetes',
    no: 'Peanøttallergi',
    tr: 'Yer fıstığı alerjisi',
    dk: 'Jordnøddeallergi',
  },
  'Item.Allergen.SESAME': {
    en: 'Sesame allergy',
    se: 'Sesamallergi',
    es: 'Alergia al sésamo',
    no: 'Sesamallergi',
    tr: 'Susam alerjisi',
    dk: 'Sesamallergi',
  },
  'Item.Allergen.SOYBEANS': {
    en: 'Soybeans allergy',
    se: 'Sojabönor allergi',
    es: 'Alergia a los frijoles',
    no: 'Soyabønneallergi',
    tr: 'Soya fasulyesi alerjisi',
    dk: 'Sojabønner allergi',
  },
  'Item.Allergen.SULPHITES': {
    en: 'Sulphites allergy',
    se: 'Sulfiter allergi',
    es: 'Alergia a los sulfitos',
    no: 'Sulfittallergi',
    tr: 'Sülfit alerjisi',
    dk: 'Sulfitallergi',
  },
  'Item.Allergen.SULPHUR_DIOXIDE': {
    en: 'Sulphur dioxide allergy',
    se: 'Svaveldioxidallergi',
    es: 'Alergia al dióxido de azufre',
    no: 'Svoveldioksid-allergi',
    tr: 'Kükürt dioksit alerjisi',
    dk: 'Svovldioxidallergi',
  },
  'Toast.Add': {
    en: (item: string) => `${item} added to cart!`,
    se: (item: string) => `${item} tillagd i varukorgen!`,
    es: (item: string) => `${item} agregar algo al carrito!`,
    no: (item: string) => `${item} lagt til i handlekurven!`,
    tr: (item: string) => `${item} sepete eklendi!`,
    dk: (item: string) => `${item} tilføjet til indkøbskurven!`,
  },
  'Cart.Title': {
    en: 'Cart',
    se: 'Varukorg',
    es: 'Carrito',
    no: 'Handlekurv',
    tr: 'Sepet',
    dk: 'indkøbskurv',
  },
  'Cart.Restaurant': {
    en: 'Restaurant',
    se: 'Restaurang',
    es: 'Restaurante',
    no: 'Restaurant',
    tr: 'Restoran',
    dk: 'Restaurant',
  },
  'Cart.ServiceType': {
    en: 'Service type',
    se: 'Servicetyp',
    es: 'Tipo de servicio',
    no: 'Servicetype',
    tr: 'Hizmet türü',
    dk: 'Servicetype',
  },
  'Cart.ServiceType.TakeAway': {
    en: 'Take away',
    se: 'Avhämtning',
    es: 'A domicilio',
    no: 'Take away',
    tr: 'Al-Git',
    dk: 'Take away',
  },
  'Cart.ServiceType.EatHere': {
    en: 'Eat here',
    se: 'Äta här',
    es: 'Comer aquí',
    no: 'Spise her',
    tr: 'Burada yiyin',
    dk: 'Spis her',
  },
  'Cart.ServiceType.Delivery': {
    en: 'Delivery',
    se: 'Leverans',
    es: 'Entrega',
    no: 'Levering',
    tr: 'Paket',
    dk: 'Levering',
  },
  'Cart.Discount': {
    en: 'Total Discount',
    se: 'Total Rabatt',
    es: 'Descuento',
    no: 'Total rabatt',
    tr: 'Toplam İndirim',
    dk: 'Samlet rabat',
  },
  'Cart.MembershipDiscount': {
    en: 'Membership Discount',
    se: 'Medlemskaps rabatt',
    es: 'Descuento de membresía',
    no: 'Medlemskapsrabatt',
    tr: 'Üyelik İndirimi',
    dk: 'Medlemsrabat',
  },
  'Cart.Total': {
    en: 'Total',
    se: 'Totalt',
    es: 'Total',
    no: 'Total',
    tr: 'Toplam',
    dk: 'Total',
  },
  'Cart.VAT': {
    en: 'VAT',
    se: 'moms',
    es: 'IVA',
    //repeated
    no: 'MVA',
    tr: 'KDV',
    dk: 'moms',
  },
  'Cart.VAT.Total': {
    en: 'Total VAT',
    se: 'Totalsumma moms',
    es: 'IVA total',
    //repeated
    no: 'Total MVA',
    tr: 'Toplam KDV',
    dk: 'Samlet moms',
  },
  'Cart.VAT.TotalExcluding': {
    en: 'Price excluding VAT',
    se: 'Pris exlusive moms',
    es: 'Precio sin IVA',
    //repeated
    no: 'Pris eks. MVA',
    tr: 'KDV hariç fiyat',
    dk: 'Pris eksklusiv moms',
  },
  'Cart.ChangePickuptime': {
    en: 'Change pickup time',
    se: 'Ändra upphämtningstid',
    es: 'Cambiar la hora de recogida',
    no: 'Endre hentetidspunkt',
    tr: 'Teslim alma saatini değiştir',
    dk: 'Ændre afhentningstidspunkt',
  },
  'Cart.Pickuptime.ASAP': {
    en: 'ASAP - As soon as possible',
    se: 'ASAP - As soon as possible',
    es: 'ASAP - Tan pronto como sea posible',
    no: 'ASAP - Så snart som mulig',
    tr: 'En kısa sürede - Mümkün olan en kısa sürede',
    dk: 'ASAP - Så hurtigt som muligt',
  },
  'Cart.Pickuptime.Today': {
    en: 'Today',
    se: 'Idag',
    es: 'Hoy',
    no: 'I dag',
    tr: 'Bugün',
    dk: 'I dag',
  },
  'Cart.AddComment': {
    en: 'Add a comment',
    se: 'Lägg till kommentar',
    es: 'Añadir un comentario',
    no: 'Legg til kommentar',
    tr: 'Yorum ekle',
    dk: 'Tilføj en kommentar',
  },
  'Cart.NoComment': {
    en: 'No comments',
    se: 'Inga kommentarer',
    es: 'Sin comentarios',
    no: 'Ingen kommentarer',
    tr: 'Yorum yok',
    dk: 'Ingen kommentarer',
  },
  'Cart.SelectCard': {
    en: 'Select card',
    se: 'Välj betalkort',
    es: 'Seleccionar tarjeta',
    no: 'Velg betalingskort',
    tr: 'Kart seç',
    dk: 'Vælg kort',
  },
  'Cart.AddDiscount': {
    en: 'Add discount code',
    se: 'Lägg till rabattkod',
    es: 'Añadir código de descuento',
    no: 'Legg til rabattkode',
    tr: 'İndirim kodu ekle',
    dk: 'Tilføj rabatkode',
  },
  'Cart.Button.Pay': {
    en: 'Pay',
    se: 'Betala',
    es: 'Pagar',
    no: 'Betal',
    tr: 'Ödeme',
    dk: 'Betal',
  },
  'Cart.Button.PayLater': {
    en: 'Order to table',
    se: 'Beställ till bordet',
    es: 'Ordenar a la mesa',
    no: 'Bestill til bordet',
    tr: 'Masaya sipariş ver',
    dk: 'Bestil til bord',
  },
  'Cart.Button.Loading': {
    en: 'Loading...',
    se: 'Vänligen vänta...',
    es: 'Cargando...',
    no: 'Vennligst vent...',
    tr: 'Yükleniyor...',
    dk: 'Indlæser...',
  },
  'Cart.Confirm': {
    en: 'Confirm',
    se: 'Bekräfta',
    es: 'Confirmar',
    no: 'Bekreft',
    tr: 'Onayla',
    dk: 'Bekræft',
  },
  'Cart.Cancel': {
    en: 'Cancel',
    se: 'Avbryt',
    es: 'Cancelar',
    no: 'Avbryt',
    tr: 'İptal et',
    dk: 'Ophæv',
  },
  'Cart.Clear.Title': {
    en: 'Cart will be cleared',
    se: 'Varukorgen kommer att tömmas',
    es: 'El carrito se borrará',
    no: 'Handlekurven blir tømt',
    tr: 'Sepet temizlenecek',
    dk: 'Indkøbskurv vil blive tømt',
  },
  'Cart.Cleared': {
    en: 'Cart is cleared',
    se: 'Varukorgen är tömd',
    es: 'El carrito está vacío',
    no: 'Handlekurven er tømt',
    tr: 'Sepet temizlendi',
    dk: 'Indkøbskurv er tømt',
  },
  'Cart.Tips.Description': {
    en: 'Say thanks with a tip!',
    se: 'Säg tack med dricks!',
    es: '¿Propina?',
    no: 'Ønsker du å legge igjen tips?',
    tr: 'Bahşiş ile teşekkür edin!',
    dk: 'Sig tak med drikkepenge!',
  },
  'Cart.Modal.SendComment': {
    en: 'Send a comment',
    se: 'Skicka med en kommentar',
    es: 'Enviar un comentario',
    no: 'Send med en kommentar',
    tr: 'Yorum gönder',
    dk: 'Send en kommentar',
  },
  'Cart.Modal.SendComment.Placeholder': {
    en: 'Your comment here',
    se: 'Din kommentar här',
    es: 'Tu comentario aquí',
    no: 'Din kommentar her',
    tr: 'Yorumunuz burada',
    dk: 'Din kommentar her',
  },
  'Cart.Modal.SendComment.Button': {
    en: 'Ok',
    se: 'Ok',
    es: 'Ok',
    no: 'Ok',
    tr: 'Tamam',
    dk: 'Okay',
  },
  'Cart.Modal.DiscountCode.Title': {
    en: 'Discount code',
    se: 'Rabattkod',
    es: 'Código de descuento',
    no: 'Rabattkode',
    tr: 'İndirim kodu',
    dk: 'Rabatkode',
  },
  'Cart.Modal.DiscountCode.Placeholder': {
    en: 'Enter your code',
    se: 'Fyll i din kod här',
    es: 'Introduce tu código',
    no: 'Fyll inn koden her',
    tr: 'Kodunuzu girin',
    dk: 'Indtast din kode',
  },
  'Cart.Modal.OutOfStock': {
    en:
      'These items are out of stock and was removed from your cart, please select something else',
    es: 'Este artículo está agotado y eliminado de su carrito, seleccione otra cosa',
    se:
      'Dessa produkter är slut i lager och har tagits bort från din varukorg, vänligen välj något annat',
    no: 'Disse produktene er utsolgt og har blitt fjernet fra handlekurven.',
    tr: 'Bu ürünler stokta yok ve sepetinizden kaldırıldı, lütfen başka bir ürün seçin',
    dk: 'Disse varer er udsolgt og blev fjernet fra din indkøbskurv, vælg venligst noget andet',
  },

  NOUSER: {
    en: 'This promocode requires you to log in',
    se: 'Denna rabattkod kräver att du loggar in',
    es: 'Este código de descuento requiere que inicie sesión',
    no: 'Denne rabattkoden krever at du logger inn',
    tr: 'Bu promosyon kodu giriş yapmanızı gerektiriyor',
    dk: 'Denne kampagnekode kræver, at du logger ind',
  },

  INVALIDVENUE: {
    en: 'This promocode is not valid at this restaurant',
    se: 'Denna rabattkod är inte giltig på denna restaurang',
    es: 'Este código de descuento no es válido en este restaurante',
    no: 'Denne rabattkoden er ikke gyldig på denne restauranten',
    tr: 'Bu promosyon kodu bu restoranda geçerli değil',
    dk: 'Denne kampagnekode er ikke gyldig på denne restaurant',
  },

  NOTACTIVEYET: {
    en: 'This promocode is not active yet',
    se: 'Denna rabattkod är inte aktiv än',
    es: 'Este código de descuento aún no está activo',
    no: 'Denne rabattkoden er ikke aktiv enda',
    tr: 'Bu promosyon kodu henüz aktif değil',
    dk: 'Denne kampagnekode er ikke aktiv endnu',
  },

  INACTIVE: {
    en: 'This promocode is not active',
    se: 'Denna rabattkod är inte aktiv',
    es: 'Este código de descuento no está activo',
    no: 'Denne rabattkoden er ikke aktiv',
    tr: 'Bu promosyon kodu aktif değil',
    dk: 'Denne kampagnekode er ikke aktiv',
  },

  EXPIRED: {
    en: 'This promocode has expired',
    se: 'Denna rabattkod har gått ut',
    es: 'Este código de descuento ha caducado',
    no: 'Denne rabattkoden har utløpt',
    tr: 'Bu promosyon kodunun süresi doldu',
    dk: 'Denne kampagnekode er udløbet',
  },

  USELIMIT: {
    en: 'This promocode has reached its use limit',
    se: 'Denna rabattkod har nått sin användningsgräns',
    es: 'Este código de descuento ha alcanzado su límite de uso',
    no: 'Denne rabattkoden har nådd sin bruksgrense',
    tr: 'Bu promosyon kodu kullanım sınırına ulaştı',
    dk: 'Denne kampagnekode har nået sin brugsgrænse',
  },

  OUTOFHOURS: {
    en: 'This promocode is not active at this time',
    se: 'Denna rabattkod är inte aktiv just nu',
    es: 'Este código de descuento no está activo en este momento',
    no: 'Denne rabattkoden er ikke aktiv nå',
    tr: 'Bu promosyon kodu şu anda aktif değil',
    dk: 'Denne kampagnekode er ikke aktiv på nuværende tidspunkt',
  },

  NOTFOUND: {
    en: 'This promocode does not exist',
    se: 'Denna rabattkod finns inte',
    es: 'Este código de descuento no existe',
    no: 'Denne rabattkoden eksisterer ikke',
    tr: 'Bu promosyon kodu mevcut değil',
    dk: 'Denne kampagnekode findes ikke',
  },

  'Cart.Modal.DiscountCode.Button': {
    en: 'Activate',
    se: 'Aktivera',
    es: 'Activar',
    no: 'Aktiver',
    tr: 'Etkinleştir',
    dk: 'Aktiver',
  },
  'Cart.Modal.Payment.Title': {
    en: 'Choose payment method',
    se: 'Välj betalningsmedel',
    es: 'Elija el método de pago',
    no: 'Velg betalingsmiddel',
    tr: 'Ödeme yöntemini seçin',
    dk: 'Vælg betalingsmetode',
  },
  'Cart.Modal.Payment.Add': {
    en: 'Add payment card',
    se: 'Lägg till betalkort',
    es: 'Añadir tarjeta de pago',
    no: 'Legg til betalingskort',
    tr: 'Ödeme kartı ekle',
    dk: 'Tilføj betalingskort',
  },
  'Cart.Modal.Payment.Button': {
    en: 'Ok',
    se: 'Ok',
    es: 'Ok',
    no: 'Ok',
    tr: 'Tamam',
    dk: 'Okay',
  },
  'Cart.Modal.PaymentError.Title': {
    en: 'Payment Error',
    se: 'Betalningsfel',
    es: 'Error de pago',
    no: 'Betalingsfeil',
    tr: 'Ödeme Hatası',
    dk: 'Betalingsfejl',
  },
  'Cart.Modal.PaymentError.Text': {
    en: 'Something went wrong with your payment, please try again',
    se: 'Något gick fel med din betalning, vänligen försök igen',
    es: 'Algo salió mal con su pago, inténtelo de nuevo',
    no: 'En feil har oppstod med betalingen, vennligst prøv igjen',
    tr: 'Ödemenizde bir sorun oluştu, lütfen tekrar deneyin',
    dk: 'Der gik noget galt med din betaling. Prøv venligst igen',
  },
  'Cart.Modal.AfterPaymentError.Text': {
    en: 'An error occurred, please try again.',
    se: 'Ett oväntat fel inträffade. Vänligen försök igen.',
    es: 'Se ha producido un error, inténtelo de nuevo.',
    no: 'En feil har oppstått, vennligst prøv igjen',
    tr: 'Bir hata oluştu, lütfen tekrar deneyin.',
    dk: 'Der opstod en fejl, prøv venligst igen.',
  },
  'Cart.Modal.PaymentError.Button': {
    en: 'Try Again',
    se: 'Försök igen',
    es: 'Vuelve a intentarlo',
    no: 'Prøv igjen',
    tr: 'Tekrar deneyin',
    dk: 'Prøv igen',
  },
  'Cart.Modal.OrderPayment.Title': {
    en: 'Payment verification',
    se: 'Betalningsverifiering',
    es: 'Verificación de pagos',
    no: 'Betalingsverifisering',
    tr: 'Ödeme doğrulama',
    dk: 'Betalingsbekræftelse',
  },
  'Cart.Modal.Error.Title': {
    en: 'Something went wrong',
    se: 'Något gick fel',
    es: 'Algo salió mal',
    no: 'Noe gikk galt',
    tr: 'Bir şeyler ters gitti',
    dk: 'Noget gik galt',
  },
  'Cart.Modal.Error.Text': {
    en: 'An error occurred, please try again.',
    se: 'Ett oväntat fel inträffade. Försök gärna igen.',
    es: 'Se ha producido un error, inténtelo de nuevo.',
    no: 'En feil har oppstått, vennligst prøv igjen',
    tr: 'Bir hata oluştu, lütfen tekrar deneyin.',
    dk: 'Der opstod en fejl, prøv venligst igen.',
  },
  'Cart.Modal.Error.DesiredTime': {
    en: 'We are closed but you can create a pre-order by choosing a desired pick-up time',
    se: 'Vi har stängt, men du kan förbeställa genom att ändra upphämtningstid',
    es:
      'Estamos cerrados, pero puede crear un pedido anticipado eligiendo la hora de recogida deseada',
    no: 'Vi har stengt, men du kan forhåndsbestille til et annet hentetidspunkt',
    tr: 'Kapalıyız ancak istediğiniz teslim alma saatini seçerek ön sipariş oluşturabilirsiniz.',
    dk:
      'Vi holder lukket, men du kan oprette en forudbestilling ved at vælge et ønsket afhentningstidspunkt',
  },
  'Cart.Modal.Error.Button': {
    en: 'Ok',
    se: 'Ok',
    es: 'Ok',
    no: 'Ok',
    tr: 'Tamam',
    dk: 'Okay',
  },

  'Cart.Modal.PaymentRefused.Title': {
    en: 'Payment Refused',
    se: 'Medges Ej',
    es: 'Pago rechazado',
    no: 'Avvist',
    tr: 'Ödeme Reddedildi',
    dk: 'Betaling afvist',
  },
  'Cart.Modal.PaymentRefused.Text': {
    en: 'Your payment was refused, please try another payment method.',
    se: 'Betalningen nekades, vänligen försök igen med en annan betalningsmetod.',
    es: 'Su pago fue rechazado, pruebe otro método de pago.',
    no: 'Betalingen ble avvist, vennligst prøv igjen med et annet betalingskort.',
    tr: 'Ödemeniz reddedildi, lütfen başka bir ödeme yöntemi deneyin.',
    dk: 'Din betaling blev afvist. Prøv venligst en anden betalingsmetode.',
  },
  'Cart.Modal.PaymentComplete.Title.First': {
    en: 'Processing Payment',
    se: 'Betalning genomförd',
    es: 'Procesando el pago',
    no: 'Betaling gjennomført',
    tr: 'Ödeme İşleniyor',
    dk: 'Behandling af betaling',
  },
  'Cart.Modal.PaymentComplete.Title.Second': {
    en: 'Creating Order',
    se: 'Skapar Order',
    es: 'Creación de orden',
    no: 'Oppretter ordre',
    tr: 'Sipariş Oluşturma',
    dk: 'Oprettelse af ordre',
  },
  'Cart.Modal.PaymentComplete.Text.First': {
    en: 'Payment processing, please wait...',
    se: 'Betalningen behandlas! Vänligen vänta...',
    es: 'Procesamiento de pago, por favor espere...',
    no: 'Betalingen behandles, vennligst vent...',
    tr: 'Ödeme işleniyor, lütfen bekleyin...',
    dk: 'Behandler betaling, vent venligst...',
  },
  'Cart.Modal.PaymentComplete.Text.Second': {
    en: 'Creating order...',
    se: 'Skapar order...',
    es: 'Creando orden...',
    no: 'Oppretter ordre...',
    tr: 'Sipariş oluşturuluyor...',
    dk: 'Opretter ordre...',
  },
  'Cart.Modal.PayLaterPending.Title': {
    en: 'Sending order',
    se: 'Skickar beställning',
    es: 'Enviando orden',
    no: 'Sender bestilling...',
    tr: 'Sipariş gönderiliyor',
    dk: 'Sender ordre',
  },
  'Cart.Modal.PayLaterPending.Text': {
    en: 'Your order is being sent to the kitchen...',
    se: 'Din beställning skickas till köket...',
    es: 'Tu pedido está siendo enviado a la cocina...',
    no: 'Din bestilling sendes til kjøkkenet...',
    tr: 'Siparişiniz mutfağa gönderiliyor...',
    dk: 'Din ordre sendes til køkkenet...',
  },
  'Cart.Modal.PayLaterComplete.Title': {
    en: 'Order sent!',
    se: 'Beställning skickad!',
    es: '¡Orden enviada!',
    no: 'Bestilling sendt!',
    tr: 'Sipariş gönderildi!',
    dk: 'Ordre sendt!',
  },
  'Cart.Modal.PayLaterComplete.Text': {
    en: 'Your order has been sent to the kitchen! You will now be redirected to the start page.',
    se: 'Din beställning har skickats till köket! Du kommer nu att återgå till startsidan.',
    es: '¡Tu pedido ha sido enviado a la cocina! Ahora será redirigido a la página de inicio.',
    no: 'Din bestilling har blitt sendt! Du blir nå sendt til startsiden.',
    tr: 'Siparişiniz mutfağa gönderildi! Şimdi başlangıç ​​sayfasına yönlendirileceksiniz.',
    dk: 'Din ordre er sendt til køkkenet! Du vil nu blive omdirigeret til startsiden.',
  },
  'Cart.DropIn.Card': {
    en: 'Credit card',
    se: 'Kort',
    es: 'Tarjeta de crédito',
    no: 'Kort',
    tr: 'Kredi kartı',
    dk: 'Kreditkort',
  },
  'Cart.DropIn.Language': {
    en: 'en-US',
    se: 'sv-SE',
    es: 'es-ES',
    no: 'nb-NO',
    tr: 'tr-TR',
    dk: 'da-US',
  },
  'Cart.DropIn.Invoice': {
    en: 'Pay with invoice',
    se: 'Betala med faktura',
    es: 'Pagar con factura',
    no: 'Betal med faktura',
    tr: 'Faturayla öde',
    dk: 'Betal med faktura',
  },
  'Cart.SignUpReminder.Title': {
    en: 'Come here often? Become our regular!',
    se: 'Är du här ofta? Bli vår stammis!',
    es: '¿Vienes a menudo? ¡Guarda tus datos para la próxima visita!',
    no: 'Er du har ofte? Registrer deg!',
    tr: 'Buraya sık sık gelir misin? Müdavimimiz olun!',
    dk: 'Besøger du os ofte? Bliv vores stamgæst!',
  },
  'Cart.SignUpReminder.Row.First': {
    en: 'Save your payment information',
    se: 'Spara din betalningsinformation',
    es: 'Guarde tus datos de pago',
    no: 'Lagre betalingsinformasjon',
    tr: 'Ödeme bilgilerinizi kaydedin',
    dk: 'Gem dine betalingsoplysninger',
  },
  'Cart.SignUpReminder.Row.Second': {
    en: 'Earn points on every order',
    se: 'Tjäna poäng på varje beställning',
    es: 'Gana puntos en cada pedido',
    no: 'Opptjen poeng på hver bestilling',
    tr: 'Her siparişte puan kazanın',
    dk: 'Optjen point på hver ordre',
  },
  'Cart.SignUpReminder.Row.Third': {
    en: 'Recieve unique offers',
    se: 'Få unika erbjudanden',
    es: 'Recibe ofertas únicas',
    no: 'Få eksklusive tilbud',
    tr: 'Benzersiz teklifler alın',
    dk: 'Modtag unikke tilbud',
  },
  'Cart.TimeMismatch.ErrorTitle': {
    en: 'Timezone mismatch',
    se: 'Tidszonsfel',
    es: 'Diferencia de zona horaria',
    no: 'Tidssone mismatch',
    tr: 'Saat dilimi uyumsuzluğu',
    dk: 'Tidszone mismatch',
  },
  'Cart.TimeMismatch.Error': {
    en:
      'An error occured, your system timezone does not match the restaurants timezone. Check your system timezone settings, restart this app and try again.',
    se:
      'Ett fel uppstod, ditt systems tidszon matchar inte restaurangens tidszon. Kontrollera dina systeminställningar, starta om denna app och försök igen.',
    es:
      'Se produjo un error, la zona horaria de su sistema no coincide con la zona horaria del restaurante. Verifique la configuración de la zona horaria de su sistema, reinicie esta aplicación y vuelva a intentarlo.',
    no:
      'En feil oppstod, systemets tidssone samsvarer ikke med restaurantens tidssone. Sjekk systemets tidssoneinnstillinger, start appen på nytt og prøv igjen.',
    tr:
      'Bir hata oluştu, sistem saat diliminiz restoranın saat dilimiyle eşleşmiyor. Sistem saat dilimi ayarlarınızı kontrol edin, bu uygulamayı yeniden başlatın ve tekrar deneyin.',
    dk:
      'Der opstod en fejl, din systems tidszone stemmer ikke overens med restaurantens tidszone. Kontroller dine systems tidszoneindstillinger, genstart denne app og prøv igen.',
  },
  'CookieSettings.Nav.Title': {
    en: 'Cookie settings',
    se: 'Cookie settings',
    es: 'Privacidad',
    no: 'Cookie-innstillinger',
    tr: 'Çerez ayarları',
    dk: 'Cookie-indstillinger',
  },
  'CookieSettings.Title': {
    en: 'Inställningar kakor',
    se: 'Cookie settings',
    es: 'configuración de cookies',
    no: 'Cookie-innstillinger',
    tr: 'Kurulum kakor',
    dk: 'Instillinger kakor',
  },
  'CookieSettings.Cookies.Title': {
    en: 'Cookies',
    se: 'Kakor',
    es: 'Cookies',
    no: 'Cookies',
    tr: 'Çerezler',
    dk: 'Cookies',
  },
  'CookieSettings.Cookies.Text': {
    en:
      'To improve your customer experience and enable full functionality of our service, we save temporary files in the form of so-called cookies. These are stored on your device and contain, among other things, settings you have made and your shopping basket.',
    se:
      'För att förbättra din kundupplevelse och möjliggöra full funktion av vår tjänst sparar vi temporära filer i form av så kallade kakor. Dessa lagras på din enhet och innehåller bland annat inställningar du har gjort samt din kundkorg.',
    es:
      'Para mejorar su experiencia como cliente y habilitar la funcionalidad completa de nuestro servicio, guardamos archivos temporales en forma de las llamadas cookies. Estos se almacenan en su dispositivo y contienen, entre otras cosas, la configuración que ha realizado y su cesta de la compra.',
    no:
      'For å forbedre gjesteopplevelsen og muliggjøre full funksjonalitet av tjenesten vår, lagrer vi midlertidige filer i form av såkalte cookies. Disse lagres på din enhet og inneholder blant annet instillinger du har lagt inn, samt din handlevogn.',
    tr:
      'Müşteri deneyiminizi geliştirmek ve hizmetimizin tam işlevselliğini sağlamak için geçici dosyaları çerez adı verilen biçimde kaydediyoruz. Bunlar cihazınızda saklanır ve diğer bilgilerin yanı sıra yaptığınız ayarları ve alışveriş sepetinizi içerir.',
    dk:
      'For at forbedre din kundeoplevelse og muliggøre fuld funktionalitet af vores service gemmer vi midlertidige filer i form af såkaldte cookies. Disse gemmes på din enhed og indeholder blandt andet indstillinger, du har foretaget, og din indkøbskurv.',
  },
  'CookieSettings.Tracking.Title': {
    en: 'Tracking',
    se: 'Spårning',
    es: 'Galletas',
    no: 'Sporing',
    tr: 'Takip',
    dk: 'Sporing',
  },
  'CookieSettings.Tracking.Text': {
    en:
      'With the aim of further developing our product, we collect anonymous information about visits to the app. This information cannot be directly connected to you as an individual and helps us understand our customers as a group.',
    se:
      'Med ändamålet att vidareutveckla vår produkt samlar vi in anyonym information om besök i appen. Denna information går alltså inte att härleda till dig som person och hjälper oss förstå våra kunder som grupp.',
    es:
      'Con el objetivo de seguir desarrollando nuestro producto, recopilamos información anónima sobre las visitas a la aplicación. Esta información no se puede relacionar directamente con usted como individuo y nos ayuda a comprender a nuestros clientes como grupo.', // Needs to be proof read
    no:
      'Med mål om å videreutvikle vårt produkt samler vi anonym informasjon om besøk i appen. Denne informasjonen kan altså ikke kobles til deg som person, og hjelper oss å forstå kundene våre som en gruppe.',
    tr:
      'Ürünümüzü daha da geliştirmek amacıyla, uygulamaya yapılan ziyaretler hakkında anonim bilgiler topluyoruz. Bu bilgiler bireysel olarak sizinle doğrudan ilişkilendirilemez ve müşterilerimizi grup olarak anlamamıza yardımcı olur.',
    dk:
      'Med det formål at videreudvikle vores produkt indsamler vi anonyme oplysninger om besøg i appen. Disse oplysninger kan ikke forbindes direkte med dig som individ og hjælper os med at forstå vores gæste som en gruppe.',
  },
  'Support.Nav.Title': {
    en: 'Support',
    se: 'Support',
    es: 'Soporte',
    no: 'Support',
    tr: 'Destek',
    dk: 'Vejledning',
  },
  'Support.Title': {
    en: 'Contact us',
    se: 'Kontakta Support',
    es: 'Contáctenos',
    no: 'Kontakt oss',
    tr: 'Bize Ulaşın',
    dk: 'Kontakt os',
  },
  'Support.Text': {
    en:
      'We can be reached fastest by email. By clicking the link below your email client will open.',
    se: 'Vi kan nås snabbast via email. Tryck på länken nedan för att öppna din emailklient.',
    es:
      'Podemos ser contactados más rápido por correo electrónico. Al hacer clic en el enlace de abajo, su cliente de correo electrónico se abrirá.',
    no: 'Du treffer oss raskest via e-post. Klikk på linken nedenfor for å sende en e-post.',
    tr:
      'Bize en hızlı e-posta ile ulaşılabilir. Aşağıdaki bağlantıya tıkladığınızda e-posta istemciniz açılacaktır.',
    dk: 'Vi kan nås hurtigst på mail. Ved at klikke på linket nedenfor åbnes din e-mail-klient.',
  },
  'Support.AccountDeletion': {
    en: 'Do you want to have your account deleted? ',
    se: 'Vill du få ditt konto borttaget? ',
    es: '¿Quieres que eliminen tu cuenta? ',
    no: 'Ønsker du å slette kontoen din?',
    tr: 'Hesabınızın silinmesini mi istiyorsunuz?',
    dk: 'Vil du slette din konto?',
  },
  'Support.Button.Text': {
    en: 'Contact us',
    se: 'Kontakta oss',
    es: 'Contáctenos',
    no: 'Kontakt oss',
    tr: 'Bize Ulaşın',
    dk: 'Kontakt os',
  },
  'Support.DeleteButton.Text': {
    en: 'Delete account',
    se: 'Ta bort konto',
    es: 'Eliminar cuenta',
    no: 'Slett konto',
    tr: 'Hesabı sil',
    dk: 'Slet konto',
  },
  'Support.DeleteButton.Error': {
    en: 'Something went wrong, please contact our support',
    se: 'Något gick fel, vänligen kontakta vår support',
    es: 'Algo salió mal, por favor contáctenos',
    no: 'Noe gikk galt, vennligst kontakt support',
    tr: 'Bir şeyler ters gitti, lütfen desteğimizle iletişime geçin',
    dk: 'Noget gik galt. Kontakt venligst vores support',
  },
  'Support.DeleteButton.Cancel': {
    en: 'Cancel',
    se: 'Avbryt',
    es: 'Cancelar',
    no: 'Avbryt',
    tr: 'İptal et',
    dk: 'Ophæv',
  },
  'Support.DeleteModal.Confirm': {
    en: 'Are you sure you want to delete your account? This action is irreversible.',
    se: 'Är du säker på att du vill ta bort ditt konto? Denna åtgärd är oåterkallelig.',
    es: '¿Estás seguro de que quieres eliminar tu cuenta? Esta acción es irreversible.',
    no: 'Er du sikker på at du vil slette kontoen din? Denne handlingen kan ikke reverseres.',
    tr: 'Hesabınızı silmek istediğinizden emin misiniz? Bu eylem geri döndürülemez.',
    dk: 'Er du sikker på, at du vil slette din konto? Denne handling kan ikke fortrydes.',
  },
  'Date.PickADate': {
    en: 'Pick a date and time',
    se: 'Välj datum och tid',
    es: 'Elige una fecha y hora',
    no: 'Velg dato og tid',
    tr: 'Bir tarih ve saat seçin',
    dk: 'Vælg en dato og tid',
  },
  'Date.PickADate.OK': {
    en: 'Ok',
    se: 'Ok',
    es: 'Ok',
    no: 'Ok',
    tr: 'Tamam',
    dk: 'Okay',
  },
  'Date.Mon': {
    en: 'Mon',
    se: 'Mån',
    es: 'Lun',
    no: 'Man',
    tr: 'Pazartesi',
    dk: 'man',
  },
  'Date.Tue': {
    en: 'Tue',
    se: 'Tis',
    es: 'Mar',
    no: 'Tir',
    tr: 'Salı',
    dk: 'tir',
  },
  'Date.Wed': {
    en: 'Wed',
    se: 'Ons',
    es: 'Mie',
    no: 'Ons',
    tr: 'Çarşamba',
    dk: 'ons',
  },
  'Date.Thu': {
    en: 'Thu',
    se: 'Tor',
    es: 'Jue',
    no: 'Tor',
    tr: 'Perşembe',
    dk: 'tor',
  },
  'Date.Fri': {
    en: 'Fri',
    se: 'Fre',
    es: 'Vie',
    no: 'Fre',
    tr: 'Cuma',
    dk: 'fre',
  },
  'Date.Sat': {
    en: 'Sat',
    se: 'Lör',
    es: 'Sab',
    no: 'Lør',
    tr: 'Cumartesi',
    dk: 'lør',
  },
  'Date.Sun': {
    en: 'Sun',
    se: 'Sön',
    es: 'Dom',
    no: 'Søn',
    tr: 'Pazar',
    dk: 'søn',
  },
  'Date.Jan': {
    en: 'jan',
    se: 'jan',
    es: 'ene',
    no: 'jan',
    tr: 'Ocak',
    dk: 'jan',
  },
  'Date.Feb': {
    en: 'feb',
    se: 'feb',
    es: 'feb',
    no: 'feb',
    tr: 'Şubat',
    dk: 'feb',
  },
  'Date.Mars': {
    en: 'mar',
    se: 'mar',
    es: 'mar',
    no: 'mar',
    tr: 'Mart',
    dk: 'mar',
  },
  'Date.Apr': {
    en: 'apr',
    se: 'apr',
    es: 'abr',
    no: 'apr',
    tr: 'Nisan',
    dk: 'apr',
  },
  'Date.May': {
    en: 'may',
    se: 'maj',
    es: 'may',
    no: 'mai',
    tr: 'Mayıs',
    dk: 'maj',
  },
  'Date.June': {
    en: 'jun',
    se: 'jun',
    es: 'jun',
    no: 'jun',
    tr: 'Haziran',
    dk: 'jun',
  },
  'Date.July': {
    en: 'jul',
    se: 'jul',
    es: 'jul',
    no: 'jul',
    tr: 'Temmuz',
    dk: 'jul',
  },
  'Date.Aug': {
    en: 'aug',
    se: 'aug',
    es: 'ago',
    no: 'aug',
    tr: 'Ağustos',
    dk: 'aug',
  },
  'Date.Sep': {
    en: 'sep',
    se: 'sep',
    es: 'sep',
    no: 'sep',
    tr: 'Eylül',
    dk: 'sep',
  },
  'Date.Oct': {
    en: 'oct',
    se: 'okt',
    es: 'oct',
    no: 'okt',
    tr: 'Ekim',
    dk: 'okt',
  },
  'Date.Nov': {
    en: 'nov',
    se: 'nov',
    es: 'nov',
    no: 'nov',
    tr: 'Kasım',
    dk: 'nov',
  },
  'Date.Dec': {
    en: 'dec',
    se: 'dec',
    es: 'dic',
    no: 'des',
    tr: 'Aralık',
    dk: 'dec',
  },
  'General.More': {
    en: 'more...',
    se: 'mer...',
    es: 'más...',
    no: 'mer...',
    tr: 'Daha...',
    dk: 'mere...',
  },
  'Order.EatHere': {
    en: 'Eat Here',
    se: 'Äta här',
    es: 'Comer aquí',
    no: 'Spise her',
    tr: 'Burada yiyin',
    dk: 'Spis her',
  },
  'Order.TakeAway': {
    en: 'Take Away',
    se: 'Avhämtning',
    es: 'A domicilio',
    no: 'Take away',
    tr: 'Al-Git',
    dk: 'Take Away',
  },
  'Store.Closed': {
    en: 'Closed',
    se: 'Stängt',
    es: 'Cerrado',
    no: 'Stengt',
    tr: 'Kapalı',
    dk: 'Lukket',
  },
  'Store.Closed.Message': {
    en: 'The app is currently closed.',
    se: 'Appen är för tillfället nedstängd.',
    es: 'La aplicación está actualmente cerrada.',
    no: 'Appen er for øyeblikket stengt.',
    tr: 'Uygulama şu anda kapalı.',
    dk: 'Appen er i øjeblikket lukket.',
  },
  'Input.Phone': {
    en: 'Phone number',
    se: 'Telefonnummer',
    es: 'Número de teléfono',
    no: 'Telefonnummer',
    tr: 'Telefon numarası',
    dk: 'Telefonnummer',
  },
  'Input.Phone.Placeholder': {
    en: 'Enter your mobile number',
    se: 'Fyll i ditt mobilnummer',
    es: 'Introduce tu número de móvil',
    no: 'Fyll inn ditt mobilnummer',
    tr: 'Cep telefonu numaranızı girin',
    dk: 'Indtast dit mobilnummer',
  },
  'Input.Password': {
    en: 'Password',
    se: 'Lösenord',
    es: 'Contraseña',
    no: 'Passord',
    tr: 'Şifre',
    dk: 'Adgangskode',
  },
  'Input.PasswordAgain': {
    en: 'Password again',
    se: 'Lösenord igen',
    es: 'Contraseña de nuevo',
    no: 'Passord igjen',
    tr: 'Tekrar şifre',
    dk: 'Adgangskode igen',
  },
  'Input.Password.Placeholder': {
    en: 'Choose your password',
    se: 'Välj ditt lösenord',
    es: 'Choose your password',
    no: 'Velg passord',
    tr: 'Şifrenizi seçin',
    dk: 'Vælg din adgangskode',
  },
  'Input.PasswordAgain.Placeholder': {
    en: 'Repeat password',
    se: 'Upprepa lösenord',
    es: 'Repetir contraseña',
    no: 'Gjenta passord',
    tr: 'Şifreyi tekrar girin',
    dk: 'Gentag adgangskoden',
  },
  'Receipt.Prompt': {
    en: 'Press here for your receipt',
    se: 'Tryck här för ditt kvitto',
    es: 'Presione aquí para su recibo',
    no: 'Klikk her for kvittering',
    tr: 'Fiş için buraya basın',
    dk: 'Tryk her for din kvittering',
  },
  'Receipt.Email': {
    en: 'Send Receipt to Email',
    se: 'Skicka kvitto till min mail',
    es: 'Enviar recibo a e-mail',
    no: 'Send kvittering på e-post',
    tr: 'Faturayı E-postaya Gönder',
    dk: 'Send kvittering til e-mail',
  },
  'Receipt.Email.Sent': {
    en: 'Your receipt has been sent!',
    se: 'Ditt kvitto har skickats!',
    es: '¡Tu recibo ha sido enviado!',
    no: 'Kvitteringen din er sendt!',
    tr: 'Faturanız gönderildi!',
    dk: 'Din kvittering er sendt!',
  },
  'Receipt.Email.Input': {
    en: 'Email:',
    se: 'Email:',
    es: 'Email:',
    no: 'E-post',
    tr: 'E-posta:',
    dk: 'E-mail:',
  },
  'Receipt.Email.Send': {
    en: 'Send',
    se: 'Skicka',
    es: 'Enviar',
    no: 'Send',
    tr: 'Gönder',
    dk: 'Send',
  },
  'Receipt.Restaurant': {
    en: 'Restaurant:',
    se: 'Restaurang:',
    es: 'Restaurante:',
    no: 'Restaurant',
    tr: 'Restoran:',
    dk: 'Restaurant:',
  },
  'Receipt.Accepted': {
    en: 'Your order has been accepted.',
    se: 'Din beställning har tagits emot.',
    es: 'Su pedido ha sido aceptado',
    no: 'Din bestilling har blitt akseptert.',
    tr: 'Siparişiniz kabul edildi.',
    dk: 'Din ordre er blevet accepteret.',
  },
  'Receipt.Finalizing': {
    en: 'The Kitchen is preparing your order!',
    se: 'Köket förbereder din beställning!',
    es: '¡La Cocina está preparando tu pedido!',
    no: 'Kjøkkenet forbereder din bestilling!',
    tr: 'Mutfak siparişinizi hazırlıyor!',
    dk: 'Køkkenet forbereder din ordre!',
  },
  'Receipt.Ready': {
    en: 'Bringing the food to your table!',
    se: 'Maten är påväg till ditt bord!',
    es: '¡Llevando la comida a tu mesa!',
    no: 'Maten er på vei til bordet ditt!',
    tr: 'Yemeğinizi masanıza getiriyoruz!',
    dk: 'Maden bringes til dit bord!',
  },
  'Receipt.HeaderText': {
    en: 'Order Confirmed!',
    se: 'Order bekräftad!',
    es: '¡Orden confirmada!',
    no: 'Ordre bekreftet!',
    tr: 'Sipariş Onaylandı!',
    dk: 'Ordre bekræftet!',
  },
  'Receipt.OrderNumberText': {
    en: 'Your Order Number:',
    se: 'Ditt ordernummer:',
    es: 'Su número de orden:',
    no: 'Ditt ordrenummer:',
    tr: 'Sipariş Numaranız:',
    dk: 'Dit ordrenummer:',
  },
  'PayCheque.SeeBill': {
    en: 'See your bill',
    se: 'Se din nota',
    es: 'Ver tu pedido',
    no: 'Se regningen din',
    tr: 'Faturanızı görün',
    dk: 'Se din regning',
  },
  'PayCheque.EntireBill': {
    en: 'Pay entire bill',
    se: 'Betala hela notan',
    es: 'Pagar cuenta total',
    no: 'Betal hele regningen',
    tr: 'Faturanın tamamını öde',
    dk: 'Betal hele regningen',
  },
  'PayCheque.Or': {
    en: 'or',
    se: 'eller',
    es: 'o',
    no: 'eller',
    tr: 'veya',
    dk: 'eller',
  },
  'PayCheque.SelectItems': {
    en: 'Select items to pay for',
    se: 'Välj vilka du vill betala',
    es: 'Seleccionar productos a pagar',
    no: 'Velg produktene du vil betale',
    tr: 'Ödeme yapılacak öğeleri seçin',
    dk: 'Vælg varer at betale for',
  },
  'PayCheque.PaySelected': {
    en: 'Pay for selected items',
    se: 'Betala valda produkter',
    es: 'Pagar productos seleccionados',
    no: 'Betal valgte produkter',
    tr: 'Seçilen öğeler için ödeme yapın',
    dk: 'Betal for udvalgte varer',
  },
  'PayCheque.YourBill': {
    en: 'Your bill',
    se: 'Din nota',
    es: 'Tu cuenta',
    no: 'Din regning',
    tr: 'Faturanız',
    dk: 'Din regning',
  },
  'PayCheque.PayOrSplit': {
    en: 'pay or split bill',
    se: 'betala eller dela notan',
    es: 'pagar o dividir cuenta',
    no: 'betal eller del regningen',
    tr: 'Faturayı öde veya böl',
    dk: 'betale eller dele regning',
  },
};

export default translations;
