import { useStorage } from 'Components/Storage';

const getLocale = () => {
  const [storage] = useStorage();
  const lang = storage.language;

  switch (lang) {
    case 'se':
      return 'sv';
    case 'en':
      return 'en';
    case 'no':
      return 'nb';
    default:
      return 'en';
  }
};

export default getLocale;
