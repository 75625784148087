import styled from 'styled-components';
import useI18n from 'i18n';
import { TranslatedString } from 'Types';
import { ReactComponent as NavigateRightIcon } from '../../../Assets/Icons/navigate-right.svg';
import { TextH2 } from '../../../Components/Text';
import TouchableOpacity from '../../../Components/OpacityTouchable';
import getImageUrlWithSize from '../../../utils/getImageUrlWithSize';
import { useCurrency } from 'Hooks';
import Icon from '../../../Components/Icon';
import { useEffect } from 'react';
import { DEVICE_SIZES, getDeviceSize } from '../../../utils/deviceSize';
import logo from '../../../Assets/Images/logo.svg';
import OutOfStockOverlay from 'Components/OutOfStockOverlay';

const Item = styled(TouchableOpacity)<{ small: boolean }>`
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 24%);
  width: ${({ small }) => (small ? 204 : 280)}px;
  margin-right: 8px;
  cursor: pointer;

  @media (min-width: 768px) {
    transition: transform 0.1s ease-in-out;

    &:hover {
      transform: scale(0.99);
    }
  }
`;
const Thumbnail = styled.div<{ image: string; small: boolean }>`
  background: url(${({ image }) => image || logo}) no-repeat center;
  background-size: ${({ image }) => (image ? 'cover' : '120px')};
  border-radius: 4px 4px 0 0;
  height: ${({ small }) => (small ? 136 : 184)}px;
  width: auto;
  position: relative;
  background-color: ${({ theme }) => theme.entryBackground};
`;
const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding: 24px 16px;
  color: rgb(33, 33, 36);
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
`;
const Price = styled.div<{ outOfStock: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme, outOfStock }) => (outOfStock ? '#bdbdc0' : theme.priceColor)};
  border-radius: 16px;
  font-weight: normal;
  color: rgb(255, 255, 255);
  font-size: 12px;
  letter-spacing: 0.32px;
  text-transform: uppercase;
  white-space: nowrap;
  height: 32px;
  padding: 8px 12px 8px 12px;

  span:not(:last-child) {
    margin-right: 4px;
  }
`;

type Props = {
  /**
   * Item image
   */
  image?: string;
  /**
   * Item title
   */
  title: TranslatedString;
  /**
   * Item price
   */
  price?: number;
  /**
   * Stock status
   */
  outOfStock: {
    item: boolean;
    category?: boolean;
  };
  /**
   * Item small
   */
  small?: boolean;
  /**
   * Item onClick
   */
  onClick: () => void;
  /**
   * render thumbnails
   */
  renderThumbnails: boolean;
};

/**
 * Home page category item component
 */
const CategoryItem = ({
  image = '',
  title,
  price,
  outOfStock = { item: true },
  small = false,
  onClick,
  renderThumbnails,
}: Props) => {
  const { i18n } = useI18n();
  const { formatAmount } = useCurrency();
  const deviceSize = getDeviceSize();

  const handleClick = () => {
    if (outOfStock.item || outOfStock.category) {
      return;
    }
    onClick();
  };

  // Preload the full-size image so that when you click it, its already loaded
  useEffect(() => {
    const img = new Image();

    // Make sure the visible images load first, start this load after a short timeout
    setTimeout(() => {
      const size =
        deviceSize === DEVICE_SIZES.SMALL
          ? { width: window.innerWidth * 2, height: 864 }
          : { width: 560, height: 368 };

      img.src = getImageUrlWithSize(image, size.width, size.height);
    }, 1000);
  }, []);

  return (
    <Item onClick={handleClick} small={small}>
      {renderThumbnails && (
        <Thumbnail
          image={getImageUrlWithSize(image, small ? 408 : 560, small ? 272 : 368)}
          small={small}>
          {/* Overlay is shown by the category if it is out of stock */}
          {outOfStock.item && !outOfStock.category && <OutOfStockOverlay />}
        </Thumbnail>
      )}
      <Info>
        <TextH2 lines={3}>{i18n(title)}</TextH2>
        {!!price && (
          <Price outOfStock={outOfStock.item}>
            <span>{formatAmount(price)}</span>
            <Icon icon={<NavigateRightIcon />} color={'#fff'} size={15} />
          </Price>
        )}
      </Info>
    </Item>
  );
};

export default CategoryItem;
