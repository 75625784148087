import uniqBy from 'lodash/uniqBy';
import * as actions from './actions';
import { ShoppingCartEvent } from '@baemingo/shopping-cart-events';
import { SocialTableEvent } from '@baemingo/social-table-events';
import { SerializedStorage as SerializedInventoryStorage } from '@baemingo/inventory-events/dist/types';
import { SerializedStorage as SerializedOrderStatusStorage } from '@baemingo/order-status-events/dist/types';
import { SerializedState as SerializedSocialTableStorage } from '@baemingo/social-table-events/dist/types';
import { getVenueMenus_EAT_HERE_results_menu } from 'Components/MenuManager/__queries__';

export function mainMenu(state = false, action: actions.Action): boolean {
  switch (action.type) {
    case actions.TOGGLE_MAIN_MENU:
      return action.isShow;
    default:
      return state;
  }
}

export function activatePush(state = false, action: actions.Action): boolean {
  switch (action.type) {
    case actions.TOGGLE_ACTIVATE_PUSH:
      return action.isShow;
    default:
      return state;
  }
}

type User = any;
type UserState = User;
export function user(state: UserState = null, action: actions.Action) {
  switch (action.type) {
    case actions.SET_USER:
      return action.user;
    case actions.LOGOUT:
      return null;
    default:
      return state;
  }
}

type EasyIdState = string | null;
export function easyId(state: EasyIdState = null, action: actions.Action): EasyIdState {
  switch (action.type) {
    case actions.SET_USER_EASYID:
      return action.easyId;
    default:
      return state;
  }
}

export function offline(state = false, action: actions.Action): boolean {
  switch (action.type) {
    case actions.SET_CONNECTION_STATUS:
      return action.offline;
    default:
      return state;
  }
}

export function alert(
  state: actions.AlertState = null,
  action: actions.Action,
): actions.AlertState {
  switch (action.type) {
    case actions.SET_ALERT:
      return action.alert;
    default:
      return state;
  }
}

export function inventory(state: actions.InventoryState = [], action: actions.Action) {
  switch (action.type) {
    case actions.SET_INVENTORY:
      return action.inventory?.items ?? [];
    default:
      return state;
  }
}

export function venue(state: actions.VenueState = null, action: actions.Action) {
  switch (action.type) {
    case actions.SET_VENUE:
      return action.venue;
    default:
      return state;
  }
}

export function minimumPickupTime(state: actions.MinimumPickupTime = 15, action: actions.Action) {
  switch (action.type) {
    case actions.SET_MINIMUM_PICKUP_TIME:
      return action.minimumPickupTime;
    default:
      return state;
  }
}

export function redirectPath(state: actions.RedirectPathState = '/', action: actions.Action) {
  switch (action.type) {
    case actions.SET_REDIRECT_PATH:
      return action.redirectPath;
    default:
      return state;
  }
}

export function showNewsMenuItem(
  state: actions.ShowNewsMenuItemState = false,
  action: actions.Action,
) {
  switch (action.type) {
    case actions.SET_SHOW_NEWS_MENU_ITEM:
      return action.showNewsMenuItem;
    default:
      return state;
  }
}

export function cartId(state: actions.CartIdState = null, action: actions.Action) {
  switch (action.type) {
    case actions.SET_CART_ID:
      return action.cartId;
    default:
      return state;
  }
}
export function cartErrorAmount(state: actions.CartErrorAmountState = 0, action: actions.Action) {
  switch (action.type) {
    case actions.SET_CART_ERRORS:
      return action.cartErrorAmount;
    default:
      return state;
  }
}

export function availableOffers(state: actions.AvailableOffersState = [], action: actions.Action) {
  switch (action.type) {
    case actions.SET_AVAILABLE_OFFERS:
      return action.availableOffers;
    default:
      return state;
  }
}

export function tableId(state: actions.TableIdState = null, action: actions.Action) {
  switch (action.type) {
    case actions.SET_TABLE_ID:
      return action.tableId;
    default:
      return state;
  }
}

export function disableApp(state: actions.DisableAppState = false, action: actions.Action) {
  switch (action.type) {
    case actions.SET_DISABLE_APP:
      return action.disabled;
    default:
      return state;
  }
}

export function defaultLanguage(
  state: actions.DefaultLanguageState = null,
  action: actions.Action,
) {
  switch (action.type) {
    case actions.SET_DEFAULT_LANGUAGE:
      return action.defaultLanguage;
    default:
      return state;
  }
}

export function qrOrder(state: actions.qrOrderState = false, action: actions.Action) {
  switch (action.type) {
    case actions.SET_QR_ORDER:
      return action.qrOrder;
    default:
      return state;
  }
}

export function companyCurrency(
  state: actions.CompanyCurrencyState = 'SEK',
  action: actions.Action,
) {
  switch (action.type) {
    case actions.SET_COMPANY_CURRENCY:
      return action.companyCurrency;
    default:
      return state;
  }
}

interface AddShoppingCartEventAction {
  type: 'ADD_SHOPPING_CART_EVENT';
  event: ShoppingCartEvent;
}

interface AddShoppingCartEventsAction {
  type: 'ADD_SHOPPING_CART_EVENTS';
  events: ShoppingCartEvent[];
}

interface MarkShoppingCartEventSentAction {
  type: 'MARK_EVENTS_SENT';
  ids: string[];
  sentAt: string;
}

export function shoppingCartEvents(
  state: ShoppingCartEvent[] = [],
  action:
    | actions.Action
    | AddShoppingCartEventAction
    | AddShoppingCartEventsAction
    | MarkShoppingCartEventSentAction,
): ShoppingCartEvent[] {
  switch (action.type) {
    case 'SET_CART_ID':
      return [];
    case 'ADD_SHOPPING_CART_EVENT':
      if (!action.event.cartId || !action.event.venueId) return state;
      return uniqBy([...state, action.event], 'eventId');
    case 'ADD_SHOPPING_CART_EVENTS':
      if (action.events.some((event) => !event.cartId || !event.venueId)) return state;
      return uniqBy([...state, ...action.events], 'eventId');
    case 'MARK_EVENTS_SENT':
      return state.map((event) => {
        if (action.ids.includes(event.eventId)) {
          return {
            ...event,
            sentAt: action.sentAt,
          };
        }

        return event;
      });
    default:
      return state;
  }
}

interface AddSocialTableEventAction {
  type: 'ADD_SOCIAL_TABLE_EVENT';
  event: SocialTableEvent;
}

interface AddSocialTableEventsAction {
  type: 'ADD_SOCIAL_TABLE_EVENTS';
  events: SocialTableEvent[];
}

interface MarkSocialTableEventSentAction {
  type: 'MARK_EVENTS_SENT';
  ids: string[];
  sentAt: string;
}

export function socialTableEvents(
  state: SocialTableEvent[] = [],
  action: AddSocialTableEventAction | AddSocialTableEventsAction | MarkSocialTableEventSentAction,
): SocialTableEvent[] {
  switch (action.type) {
    case 'ADD_SOCIAL_TABLE_EVENT':
      return uniqBy([...state, action.event], 'eventId');
    case 'ADD_SOCIAL_TABLE_EVENTS':
      return uniqBy([...state, ...action.events], 'eventId');
    case 'MARK_EVENTS_SENT':
      return state.map((event) => {
        if (action.ids.includes(event.eventId)) {
          return {
            ...event,
            sentAt: action.sentAt,
          };
        }

        return event;
      });
    default:
      return state;
  }
}

export interface InventorySnapshotState {
  snapshot: SerializedInventoryStorage | null;
  loading: boolean;
  timestamp: string | null;
}

export function inventorySnapshot(
  state: InventorySnapshotState = {
    snapshot: null,
    loading: true,
    timestamp: null,
  },
  action: any,
) {
  switch (action.type) {
    case 'SET_INVENTORY_SNAPSHOT':
      return {
        snapshot: action.snapshot,
        timestamp: action.timestamp,
        loading: false,
      };
    default:
      return state;
  }
}

export interface OrderStatusSnapshotState {
  snapshot: SerializedOrderStatusStorage | null;
  loading: boolean;
  timestamp: string | null;
}

export function orderStatusSnapshot(
  state: OrderStatusSnapshotState = {
    snapshot: null,
    loading: true,
    timestamp: null,
  },
  action: any,
) {
  switch (action.type) {
    case 'SET_ORDER_STATUS_SNAPSHOT':
      return {
        snapshot: action.snapshot,
        timestamp: action.timestamp,
        loading: false,
      };
    default:
      return state;
  }
}

export type UserMembershipState = null | {
  title: string;
  rate: number;
};
export function userMembership(state: UserMembershipState = null, action: any) {
  switch (action.type) {
    case actions.SET_USER_MEMBERSHIP:
      return action.membership;
    default:
      return state;
  }
}

export type CompanyHasMembershipCardsState = boolean;
// NB - we use true here to keep current behaviour.
export function companyHasMembershipCards(state = true, action: any) {
  switch (action.type) {
    case actions.SET_COMPANY_HAS_MEMBERSHIP_CARDS:
      return action.companyHasMembershipCards;
    default:
      return state;
  }
}

export interface SocialTableSnapshotState {
  snapshot: SerializedSocialTableStorage | null;
  loading: boolean;
  timestamp: string | null;
}
export function socialTableSnapshot(
  state: SocialTableSnapshotState = {
    snapshot: null,
    loading: true,
    timestamp: null,
  },
  action: any,
) {
  switch (action.type) {
    case 'SET_SOCIAL_TABLE_SNAPSHOT':
      return {
        snapshot: action.snapshot,
        timestamp: action.timestamp,
        loading: false,
      };
    default:
      return state;
  }
}

export function loginScreen(state = '', action: actions.Action): string {
  switch (action.type) {
    case actions.SET_LOGIN_SCREEN:
      return action.screen;
    default:
      return state;
  }
}

export function loginModal(state = false, action: actions.Action): boolean {
  switch (action.type) {
    case actions.SET_LOGIN_POPUP:
      return action.show;
    default:
      return state;
  }
}

type MenuState = getVenueMenus_EAT_HERE_results_menu | null | false;
interface SetMenu {
  type: typeof actions.SET_MENU;
  menu: getVenueMenus_EAT_HERE_results_menu;
}
type MenuAction = SetMenu;
export function menu(state: MenuState = null, action: MenuAction): MenuState {
  switch (action.type) {
    case actions.SET_MENU:
      return action.menu;
    default:
      return state;
  }
}

export interface State {
  mainMenu: boolean;
  activatePush: boolean;
  user: User;
  easyId: string;
  offline: boolean;
  alert: actions.AlertState;
  inventory: actions.InventoryState;
  venue: actions.VenueState;
  minimumPickupTime: actions.MinimumPickupTime;
  redirectPath: actions.RedirectPathState;
  showNewsMenuItem: actions.ShowNewsMenuItemState;
  shoppingCartEvents: actions.ShoppingCartEventState;
  socialTableEvents: actions.SocialTableEventState;
  socialTableSnapshot: any;
  cartId: actions.CartIdState;
  tableId: actions.TableIdState;
  userMembership: actions.MembershipState;
  companyHasMembershipCards: actions.CompanyHasMembershipCardsState;
  cartErrorAmount: actions.CartErrorAmountState;
  availableOffers: actions.AvailableOffersState;
  disableApp: actions.DisableAppState;
  qrOrder: actions.qrOrderState;
  companyCurrency: actions.CompanyCurrencyState;
  defaultLanguage: actions.DefaultLanguageState;
  loginScreen: actions.LoginScreenState;
  loginModal: boolean;
  menu: MenuState;
}
