import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useI18n from 'i18n';
import { useBridgeApi, useCurrency, useDispatch, useSelector } from 'Hooks';
import { ReactComponent as NavigateRightIcon } from '../../../Assets/Icons/navigate-right.svg';
import { TextH2, TextSpan } from 'Components/Text';
import TouchableOpacity from '../../../Components/OpacityTouchable';
import getImageUrlWithSize from '../../../utils/getImageUrlWithSize';
import Icon from '../../../Components/Icon';
import { useShoppingCartApi } from 'Components/ShoppingCartUniverse';
import { DEVICE_SIZES, getDeviceSize } from '../../../utils/deviceSize';

import logo from '../../../Assets/Images/logo.svg';
import { useConfig } from '../../../Components/ConfigProvider';
import { useStorage } from '../../../Components/Storage';
import { setLoginModal } from '../../../actions';

const Wrapper = styled(TouchableOpacity)<{ instant?: boolean }>`
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 24%);
  width: auto;
  margin-bottom: 8px;
  text-decoration: none;
  transition: 0.2s opacity;
  background: rgb(255, 255, 255);
  display: flex;
  flex-direction: ${({ instant }) => (instant ? 'row-reverse' : 'column')};
  padding: ${({ instant }) => (instant ? 8 : 0)}px;
  animation: fade-in 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  cursor: pointer;

  @media (min-width: 768px) {
    transition: transform 0.1s ease-in-out;

    &:hover {
      transform: scale(0.99);
    }
  }
`;
const Thumbnail = styled.div<{ image: string; instant?: boolean }>`
  background: url(${({ image }) => image || logo}) no-repeat center;
  background-color: ${({ theme }) => theme.entryBackground};
  background-size: ${({ image }) => (image ? 'cover' : '120px')};
  border-radius: ${({ instant }) => (instant ? '2px' : '4px 4px 0px 0px')};
  min-height: ${({ instant }) => (instant ? '100%' : '240px')};
  min-width: ${({ instant }) => (instant ? '96px' : 'auto')};
  position: relative;
`;
const Info = styled.div<{ instant?: boolean }>`
  display: flex;
  flex-direction: ${({ instant }) => (instant ? 'column' : 'row')};
  align-items: ${({ instant }) => (instant ? 'flex-start' : 'center')};
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: ${({ instant }) => (instant ? '4px 8px' : '16px')};
  color: rgb(33, 33, 36);
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
  border-radius: 0 0 4px 4px;
  width: 100%;
`;
const EntryText = styled.div``;
const Price = styled.div<{ instant: boolean; outOfStock: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme, outOfStock }) => (outOfStock ? '#bdbdc0' : theme.priceColor)};
  border-radius: 16px;
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.32px;
  text-transform: uppercase;
  height: 32px;
  padding: 8px 12px;
  margin-top: ${({ instant }) => (instant ? 8 : 0)}px;

  span {
    white-space: nowrap;
  }

  span:not(:last-child) {
    margin-right: 4px;
  }
`;

const Title = styled(TextH2)`
  color: rgb(33, 33, 36);
`;
const Description = styled(TextSpan)`
  color: rgb(117, 117, 120);
  font-weight: normal;
`;

const OutOfStockOverlay = styled.div<{ instant: boolean }>`
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(66, 66, 69, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  font-size: ${({ instant }) => (instant ? '11px' : '16px')};
  line-height: ${({ instant }) => (instant ? '12px' : '16px')};
  font-weight: 600;
  letter-spacing: 0.27px;
  text-transform: uppercase;
`;

type Props = {
  /**
   * categoryId
   */
  categoryId: string;
  /**
   * instant
   */
  instant: boolean;
  /**
   * price
   */
  price: number;
  /**
   * item
   */
  item: any;
  /**
   * id
   */
  id: string;
  /**
   * outOfStock
   */
  outOfStock: boolean;
};

/**
 * Entry component
 */
const Entry = ({
  categoryId,
  id,
  item,
  price = 0,
  instant = false,
  outOfStock = false,
}: Props) => {
  const { i18n } = useI18n();
  const dispatch = useDispatch();
  const params = useParams<{ venue_id?: string }>();
  const config = useConfig();
  const [storageData] = useStorage();
  const qrOrder = useSelector((state) => state.qrOrder);
  const venueId = params?.venue_id || '';
  const { image, title, description } = item;
  const { push } = useHistory();
  const shoppingCartApi = useShoppingCartApi();
  const api = useBridgeApi();
  const { formatAmount } = useCurrency();
  const deviceSize = getDeviceSize();
  const authToken = storageData.authToken;
  const isLoggedIn = !!authToken;

  const handlePush = () => {
    if (outOfStock) {
      return;
    }

    if (instant) {
      // Allow "Anonymous Purchase" check.
      if (!config.ANONYMOUS_PURCHASE && !isLoggedIn && (!qrOrder || !storageData.qrOrder)) {
        // If restaurant doesn't allow anonymous purchases & the user is not logged in, show login modal (Unless it's table ordering).
        dispatch(setLoginModal(true));
        return;
      }

      toast(i18n('Toast.Add', i18n(item.titleLang)), {
        position: 'bottom-center',
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      api.vibrate('impactLight');
      shoppingCartApi.addItem(
        i18n(item.titleLang),
        item.id,
        id,
        [],
        [],
        price,
        item.disableMembershipDiscount,
        categoryId,
      );
      return;
    }

    push(`/order/${venueId}/menu/${categoryId}/item/${item.id}`);
  };

  // Preload the full-size image so that when you click it, its already loaded
  useEffect(() => {
    if (!image?.file) {
      return;
    }
    const img = new Image();

    // Make sure the visible images load first, start this load after a short timeout
    setTimeout(() => {
      const size =
        deviceSize === DEVICE_SIZES.SMALL
          ? { width: window.innerWidth * 2, height: 864 }
          : { width: instant ? 192 : 944, height: instant ? 130 : 480 };

      img.src = getImageUrlWithSize(image.file.url, size.width, size.height);
    }, 1000);
  }, []);

  const imageSize =
    deviceSize === DEVICE_SIZES.SMALL
      ? {
          width: instant ? 192 : (window.innerWidth - 16) * 2,
          height: instant ? 130 : 480,
        }
      : {
          width: instant ? 192 : 944,
          height: instant ? 130 : 480,
        };

  return (
    <Wrapper instant={instant} onClick={handlePush}>
      {image && (
        <Thumbnail
          image={getImageUrlWithSize(image?.file?.url, imageSize.width, imageSize.height)}
          instant={instant}>
          {outOfStock && (
            <OutOfStockOverlay instant={instant}>{i18n('General.OutOfStock')}</OutOfStockOverlay>
          )}
        </Thumbnail>
      )}
      <Info instant={instant}>
        <EntryText>
          <Title lines={2}>{i18n(item.titleLang)}</Title>
          {description && <Description lines={1}>{description}</Description>}
        </EntryText>
        {!!price && (
          <Price instant={instant} outOfStock={outOfStock}>
            <span>{formatAmount(price)}</span>{' '}
            {!instant && <Icon icon={<NavigateRightIcon />} color={'#fff'} size={15} />}
          </Price>
        )}
      </Info>
    </Wrapper>
  );
};

export default Entry;
