import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';
import matches from 'lodash/matches';
import find from 'lodash/find';
import uniq from 'lodash/uniq';
import dayjs from 'dayjs';
import { openingHoursFormatTime } from '../../../utils/venueOpeningHours';
import { formatDistance, parseServiceType } from 'Scenes/StoreListing/utils';
import { useConfig } from 'Components/ConfigProvider';
import Button from '../../../Components/Button';
import useI18n from '../../../i18n';
import { VenuesWithDistance } from '../index';
import { getVenues_venues_availabilities } from '../__queries__';

const Wrapper = styled.div<{ active: boolean }>`
  animation: fade-in 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  position: relative;
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
`;

const Inner = styled.div<{ zIndex: number; active: boolean }>`
  z-index: ${(props) => props.zIndex};
  box-shadow: 0 0 7px 4px ${(props) => (props.active ? 'rgba(0,0,0,0.38)' : 'transparent')};
  position: ${(props) => (props.active ? 'absolute' : 'relative')};
  width: 100%;

  @media (min-width: 992px) {
    width: ${(props) => (props.active ? '500px' : '100%')};
    left: ${(props) => (props.active ? '50%' : 0)};
    transform: ${(props) => (props.active ? 'translateX(-50%)' : 'translateX(0)')};
  }
`;

const Divider = styled.div<{ height: number }>`
  height: ${(props) => `${props.height}px`};
`;

const Fade = styled.div<{ paddingLeft: number }>`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  height: var(--height);
  width: 100%;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s linear;
`;

const VenueName = styled.div`
  color: #212124;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
  margin: 0 0 12px 0;
`;

const Venue = styled.div<{ activeVenue: boolean }>`
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  background: #ffffff;
  position: relative;
  border-bottom: solid 6px rgb(238, 238, 241);

  @media (min-width: 768px) {
    &:hover ${VenueName} {
      text-decoration: ${({ activeVenue }) => (activeVenue ? 'none' : 'underline')};
    }
  }
`;

const VenueInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const VenueInfo = styled.div``;

const Distance = styled.div`
  padding: 0 18px;
  background: #f5f5f8;
  border-radius: 16px;
  height: 32px;
  color: #212124;

  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;
`;

const VenueRow = styled.div`
  color: rgb(97, 97, 100);
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.24px;
  line-height: 16px;
  margin: 0 0 2px 0;
`;

const ServiceTypeWrapper = styled.div<{ active: boolean }>`
  background: rgb(245, 245, 248);
  padding: 24px 24px 40px 24px;
  display: ${(props) => (props.active ? 'block' : 'none')};
`;

const ServiceTypeInner = styled.div``;

const ServiceTypeDescription = styled.div`
  color: rgb(33, 33, 36);
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.2px;
  line-height: 16px;
  margin: 8px 0 24px 0;
`;

type Props = {
  venue: VenuesWithDistance;
  activeVenue: string | null;
  setActiveVenue: (venueId: string | null) => void;
  onSubmit: (venueId: string, serviceType: string) => void;
};

const VenueItem = ({ venue, activeVenue, setActiveVenue, onSubmit }: Props) => {
  const { i18n } = useI18n();
  const config = useConfig();
  const venueItemRef = useRef<HTMLDivElement>(null);
  const availabilities: getVenues_venues_availabilities | undefined | null =
    venue.availabilities.length > 0
      ? find(venue.availabilities, matches({ dayOfWeek: dayjs().format('dddd').toUpperCase() }))
      : null;

  const openingHours = openingHoursFormatTime(availabilities);
  const formattedDistance = formatDistance(venue);
  const serviceTypes = useMemo(
    () =>
      uniq(venue.menus.map((menu) => menu.serviceType)).filter((status) =>
        ['TAKE_AWAY', 'EAT_HERE'].includes(status),
      ),
    [venue],
  );
  const active = activeVenue === venue.id;
  const zIndex = active ? 1 : 0;
  const fadeStyle: any = active ? { opacity: 1, visibility: 'visible' } : {};

  const handleClick = (id: string | null) => {
    setActiveVenue(id);
    venueItemRef?.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
  };

  const zip = venue.zip ?? venue.location.zip;

  return (
    <Wrapper ref={venueItemRef} active={active}>
      <Fade
        onClick={() => handleClick(null)}
        style={fadeStyle}
        paddingLeft={window.sidebarWidth}
      />
      <Inner zIndex={zIndex} active={active}>
        <Venue onClick={() => handleClick(venue.id)} activeVenue={Boolean(activeVenue)}>
          <VenueName>{venue.name}</VenueName>
          <VenueInfoWrapper>
            <VenueInfo>
              <VenueRow>{venue.location.name}</VenueRow>
              <VenueRow>
                {zip} {venue.location.city}
              </VenueRow>
              <VenueRow>
                {`${i18n('StoreList.Today')}: `}
                {i18n(openingHours)}
              </VenueRow>
            </VenueInfo>
            {formattedDistance && <Distance>{formattedDistance}</Distance>}
          </VenueInfoWrapper>
        </Venue>
        <ServiceTypeWrapper active={active}>
          {serviceTypes.map((type) => {
            if (!config.ALLOWED_SERVICE_TYPES.includes(type)) {
              return;
            }
            const serviceType = parseServiceType(type, i18n);
            return (
              <ServiceTypeInner key={serviceType.id}>
                <Button
                  text={serviceType.name}
                  handleClick={() => onSubmit(venue.id, serviceType.id)}
                  width={'100%'}
                />
                <ServiceTypeDescription>{serviceType.description}</ServiceTypeDescription>
              </ServiceTypeInner>
            );
          })}
        </ServiceTypeWrapper>
      </Inner>
      {active && <Divider height={136} />}
    </Wrapper>
  );
};

export default VenueItem;
